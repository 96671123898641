(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'LoginService'];

    function ResetFinishController ($stateParams, $timeout, Auth, LoginService) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#password').focus();});

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                var encrytedResetPassword = encryptPassword(vm.resetAccount.password);
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: encrytedResetPassword}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function encryptPassword(plainString)
        {
            var iv = "b508d555ef6199ed5ced97caf0ff22cb";
            var salt = "b936454f90e756b033eb21aca9a88b66";
            var keySize = 128;
            var iterationCount = 10000;
            var passPhrase = "NAimIodPNkqOyHQXmEpoFA==Sdty=jHY";
            var aesUtil = new AesUtil(keySize, iterationCount);
            var encryptedString = aesUtil.encrypt(salt, iv, passPhrase, plainString);
            return encryptedString;
        }
    }
})();
