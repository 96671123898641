(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ClearanceGivenDetailController', ClearanceGivenDetailController);

    ClearanceGivenDetailController.$inject = ['$scope','$stateParams','Principal', 'LoginService', '$state','entity', 'citizenServiceLog', 'DepartmentSrvDetail','CitizenServiceCompleteDetails', 'ClearanceGivenDetail'];

    function ClearanceGivenDetailController ($scope, $stateParams, Principal, LoginService, $state, entity, citizenServiceLog, DepartmentSrvDetail, CitizenServiceCompleteDetails, ClearanceGivenDetail) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.departmentSrvDetail = [];
        vm.clearanceGivenDetail = [];
        vm.serviceName = $stateParams.serviceName;
        // vm.applyForService = applyForService;
        vm.citizenServiceDetails = entity;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadCitizenServiceDetails($stateParams.serviceId);
            });
        }

        function register () {
            $state.go('register');
        }

        function loadCitizenServiceDetails(serviceId) {
            ClearanceGivenDetail.query({serviceId:serviceId},function (result) {
                vm.clearanceGivenDetail = result;
            });
        }
    }
})();
