(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DashboardByServiceController', DashboardByServiceController);

    DashboardByServiceController.$inject = ['$uibModalInstance','$timeout', '$scope', '$stateParams', 'DashboardServiceStatus'];

    function DashboardByServiceController ( $uibModalInstance,$timeout, $scope, $stateParams, DashboardServiceStatus) {
        var vm = this;
        vm.clear = clear;
        vm.serviceName= $stateParams.servicename;
        vm.status = $stateParams.status;
        loadDashboardServiceStatus();

        function loadDashboardServiceStatus()
        {
           vm.dashboardServiceStatus = DashboardServiceStatus.query({serviceId:$stateParams.serviceId,status:$stateParams.status});

        }
         function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
