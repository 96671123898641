(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('SelfCertificationThroughOwnEngineerDetailController', SelfCertificationThroughOwnEngineerDetailController);

    SelfCertificationThroughOwnEngineerDetailController.$inject = ['$scope', 'SelfCertificationByOwnEngineer'];

    function SelfCertificationThroughOwnEngineerDetailController($scope, SelfCertificationByOwnEngineer) {

        var vm = this;
        $scope.currentPage = 1;
        $scope.pageSize = 10;

        vm.selfCertificationByOwnEngineers = [];

        loadAll();

        function loadAll() {
            SelfCertificationByOwnEngineer.query(function(result) {
                vm.selfCertificationByOwnEngineers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
