(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServiceFormDialogController', ServiceFormDialogController);

    ServiceFormDialogController.$inject = ['$sce','$timeout', '$scope', '$stateParams', '$uibModalInstance','ServiceFormField'];

    function ServiceFormDialogController($sce,$timeout, $scope, $stateParams, $uibModalInstance,ServiceFormField) {

        var vm = this;

        vm.serviceFormFields = [];
        vm.clear = clear;
        $scope.$sce = $sce;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAll();

        function loadAll() {
           ServiceFormField.query(function(result) {
                vm.serviceFormFields = result;
//                console.log(vm.serviceFormFields);
                vm.searchQuery = null;

            });
        }
    }
})();
