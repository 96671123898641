(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('NotificationDetailsController', NotificationDetailsController);

    NotificationDetailsController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Notification', 'NotificationByApplication'];

    function NotificationDetailsController($scope, $rootScope, $stateParams, previousState, entity, Notification, NotificationByApplication) {
        var vm = this;

        vm.notification = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:notificationUpdate', function(event, result) {
            vm.notification = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
