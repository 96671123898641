(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ViewCharteredCitizen', ViewCharteredCitizen);

    ViewCharteredCitizen.$inject = ['$uibModalInstance','$stateParams','$scope', 'Principal', 'LoginService', '$state', 'citizenServiceDetail', 'AssignChartered', 'CitizenServiceDetails', 'DepartmentUserProfile', 'Citizen','$window'];

    function ViewCharteredCitizen ($uibModalInstance,$stateParams, $scope, Principal, LoginService, $state,citizenServiceDetail, AssignChartered, CitizenServiceDetails, DepartmentUserProfile, Citizen,$window) {
        var vm = this;

//        alert("gg");
        vm.save = save;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.charteredDetail ={};
        vm.citizenServiceDetail = citizenServiceDetail[0];
        vm.clear = clear;
        console.log($stateParams.citizenId);
        vm.citizenId = $stateParams.citizenId;
         console.log(vm.chartered_id);
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        vm.charteredDetail = Citizen.get({id:vm.citizenId}, function(result){
        vm.chartered = result;
        console.log(vm.charteredDetail);
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();
        }

       function save()
        {
            //$scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
            vm.citizenServiceDetail.chartered_id = $scope.selectedChartered.id;
            alert($scope.selectedChartered.id);
            vm.citizenServiceDetail.isCharteredAssign = true;
            //console.log(vm.citizenServiceDetail);
            vm.citizenServiceDetail.lastComment="Chartered Assigned";
            CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);

            vm.citizenServiceLog.citizenId = $stateParams.citizenId;
            vm.citizenServiceLog.serviceId = $stateParams.serviceId;
            vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
            vm.citizenServiceLog.actionTaken = "Chartered Assigned";
            console.log(vm.citizenServiceLog);
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function onUpdateCitizenServiceDetailSuccess(result){
        console.log(result);
        vm.isSaving = false;
        clear();
        }

        function onUpdateCitizenServiceDetailError(result){
        vm.isSaving = false;
        }

         function onSaveServiceLogSuccess(result)
                {
                    $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
                    citizenServiceLogid=result.id;
                    console.log(vm.citizenServiceDetail);
                    vm.citizenServiceDetail.lastComment="Chartered Assigned";
                    CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);
                }

                function onSaveServiceLogError()
                        {
                            vm.isSaving = false;
                        }
    }
})();
