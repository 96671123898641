(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('GovernmentDialogController', GovernmentDialogController);

    GovernmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'GovernmentType', 'Government'];

    function GovernmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, GovernmentType, Government) {
        var vm = this;

        vm.government = entity;
        vm.clear = clear;
        vm.save = save;

        loadAll();

        function loadAll() {
            GovernmentType.query(function(result) {
                vm.governmentTypes = result;
                vm.searchQuery = null;
            });
        }

         load();
                  function load () {
                               vm.id = vm.government.governmentTypeId;
                              // alert(vm.id);
                               GovernmentType.get({id: vm.id},function(result){
                                     vm.districtvalue=result;
                                     $scope.selectedGovernmentType = {id: result.id, governmentType: result.governmentType}
                                                });
                                }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            //alert($scope.selectedGovernmentType.id);

            vm.government.governmentTypeId = $scope.selectedGovernmentType.id;
          //  vm.government.governmentName = vm.governmentTypes.allgovernments.governmentType + " of " + vm.government.governmentName;
            vm.government.governmentName = vm.government.governmentName;
            if (vm.government.id !== null) {
                Government.update(vm.government, onSaveSuccess, onSaveError);
            } else {
                Government.save(vm.government, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:governmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
