(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('eSign', eSign);

    eSign.$inject = ['$resource'];

    function eSign ($resource) {
        var resourceUrl =  'api/serviceApproval/:aadhaar';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                console.log(data);
//                    if (data) {
//                        data = angular.fromJson(data);
//                    }
                    return data;
                }
            },
            'update': { method:'PUT',
                transformResponse: function (data) {
                console.log(data);
                return data;
             }
             }
        });
    }
})();
