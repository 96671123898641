(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenServiceAttachmentDeleteController',CitizenServiceAttachmentDeleteController);

    CitizenServiceAttachmentDeleteController.$inject = ['$uibModalInstance', 'entity', 'CitizenServiceAttachment'];

    function CitizenServiceAttachmentDeleteController($uibModalInstance, entity, CitizenServiceAttachment) {
        var vm = this;

        vm.citizenServiceAttachment = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CitizenServiceAttachment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
