(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .controller('ClearanceReportController', ClearanceReportController);

    ClearanceReportController.$inject = ['$scope','$stateParams', 'Principal', 'LoginService', '$state','entity', 'citizenServiceLog', 'DepartmentSrvDetail','CitizenServiceDetailsByAppNo','ClearanceGivenDetail'];

    function ClearanceReportController ($scope,$stateParams, Principal, LoginService, $state, entity, citizenServiceLog, DepartmentSrvDetail, CitizenServiceDetailsByAppNo, ClearanceGivenDetail) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.departmentSrvDetail = [];
        vm.citizenServiceDetails = entity;
        vm.citizenServiceLog = citizenServiceLog;
        vm.clearanceGivenDetail = null;
        vm.loadCitizenServiceDetails = [];
        vm.loadCitizenServiceDetails = loadCitizenServiceDetails;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadAll();
            });
        }

        function register () {
            $state.go('register');
        }

        function loadAll() {
            DepartmentSrvDetail.query(function(result) {
                vm.departmentSrvDetail = result;
                vm.searchQuery = null;
            });
        }

        function loadCitizenServiceDetails(serviceId, serviceName) {
            $state.go('clearanceGivenDetail', {serviceId: serviceId, serviceName:serviceName});
        }

    }
})();
