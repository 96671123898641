(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('OneTimePasswordDetailController', OneTimePasswordDetailController);

    OneTimePasswordDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OneTimePassword'];

    function OneTimePasswordDetailController($scope, $rootScope, $stateParams, previousState, entity, OneTimePassword) {
        var vm = this;

        vm.oneTimePassword = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:oneTimePasswordUpdate', function(event, result) {
            vm.oneTimePassword = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
