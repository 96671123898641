(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', 'Principal', 'LoginService', '$state','ContactUs', 'CheckContent'];

    function ContactController ($scope, Principal, LoginService, $state, ContactUs, CheckContent) {
        var vm = this;
        vm.save=save;
        vm.submit = true;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });



        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function save () {
            alert("success");
            vm.isSaving = true;
            ContactUs.save(vm.contactUs, onSaveSuccess, onSaveError);

         }

        function onSaveSuccess (result) {
            vm.contactUs = null;
            vm.isSaving = false;

        }

        function onSaveError () {
            vm.isSaving = false;
        }
        function register () {
            $state.go('register');
        }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))});

    }
})();
