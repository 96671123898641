(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenDashboardController', CitizenDashboardController);

    CitizenDashboardController.$inject = ['$window','$scope', 'Principal', 'LoginService', '$state', 'CitizenServiceCompleteDetails','CitizenServiceCompleteDetailsByChartered'];

    function CitizenDashboardController ($window, $scope, Principal, LoginService, $state, CitizenServiceCompleteDetails,CitizenServiceCompleteDetailsByChartered) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
//        vm.citizenSercviceCompleteDetails = [];
        getAccount();
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.authorities = account.authorities;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadCitizenServiceDetails();
            });
        }
        function register () {
            $state.go('register');
        }
        function loadCitizenServiceDetails()
        {
           if(vm.authorities=="ROLE_USER"){
            vm.citizenServiceCompleteDetails = CitizenServiceCompleteDetails.query({citizenId : vm.account.id});
            console.log(vm.citizenServiceCompleteDetails);
           }
           if(vm.authorities=="ROLE_CHARTERED"){
           vm.citizenServiceCompleteDetails = CitizenServiceCompleteDetailsByChartered.query({charteredId : vm.account.id});

          }
          if(vm.authorities=="ROLE_LICENSE_USER"){
             vm.citizenServiceCompleteDetails = CitizenServiceCompleteDetails.query({citizenId : vm.account.id});

            }
        }
        $scope.openNewLink = function() {
        		$window.open('https://egrashry.nic.in/', '_blank');
        	};
    }
})();
