(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('selfCertified', {
            parent: 'app',
            url: '/self-certified',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/self-certified/self-certified.html',
                    controller: 'SelfCertifiedController',
                    controllerAs: 'vm'
                }
            }
        })

        .state('inspection-details-uploaded-By-Chartered', {
            parent: 'selfCertified',
            url: '/inspection-details-uploaded-By-Chartered?:citizenId:serviceId:applicationNo',
               data: {
                   authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
               },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                   $uibModal.open({
                       templateUrl: 'app/citizen-dashboard/view-inspection-report.html',
                       controller: 'viewInspectionReportController',
                       controllerAs: 'vm',
                       backdrop: 'static',
                       size: 'lg',
                       resolve: {
                            entity: ['CeseElectricalInstallationDetailsByApplicationNo', function(CeseElectricalInstallationDetailsByApplicationNo) {
                                return CeseElectricalInstallationDetailsByApplicationNo.get({applicationNo : $stateParams.applicationNo}).$promise;
                            }]

                        }
                   }).result.then(function() {
                         $state.go('self-certified', null, { reload: 'self-certified' });
                     }, function() {
                         $state.go('^');
                     });
                }]

        });
    }
})();
