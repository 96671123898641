(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('verifyPaymentController', verifyPaymentController);

    verifyPaymentController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ProjectServicePaymentDetailsByProjectAndService','Projectservicedetail','projectServiceLog','ProjectServiceLog'];
    function verifyPaymentController ($timeout, $scope, $stateParams, $uibModalInstance,ProjectServicePaymentDetailsByProjectAndService,Projectservicedetail,projectServiceLog,ProjectServiceLog) {
        var vm = this;
        vm.clear = clear;
        vm.projectService=$stateParams.projectService;
        vm.projectServiceLog=projectServiceLog;
        vm.verifyPayment = verifyPayment;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllPayments();
        function loadAllPayments() {
            vm.projectServicePaymentDetails=ProjectServicePaymentDetailsByProjectAndService.query({projectid: vm.projectService.projectid,serviceid: vm.projectService.serviceid});
            console.log(vm.projectServicePaymentDetails);
        }

        function verifyPayment(){
            vm.projectService.isPaymentVerified=true;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceDetailSuccess,onUpdateProjectServiceDetailError);
        }
        function onUpdateProjectServiceDetailSuccess(result)
        {
//            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="PaymentVerified";
            ProjectServiceLog.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
        function onUpdateProjectServiceDetailError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveSuccess(result)
        {
//            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {}
    }

})();
