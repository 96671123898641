(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('User_kyc_verificationController', User_kyc_verificationController);

    User_kyc_verificationController.$inject = ['User_kyc_verification'];

    function User_kyc_verificationController(User_kyc_verification) {

        var vm = this;

        vm.user_kyc_verifications = [];

        loadAll();

        function loadAll() {
            User_kyc_verification.query(function(result) {
                vm.user_kyc_verifications = result;
                vm.searchQuery = null;
            });
        }
    }
})();
