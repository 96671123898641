(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('viewInspectionReportByOwnEngineerController', viewInspectionReportByOwnEngineerController);

    viewInspectionReportByOwnEngineerController.$inject = ['$window','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SelfCertificationThroughOwnEngineersByApplicationNo', 'FileManagement', 'SelfCertificationByOwnEngineer','downloadService'];

    function viewInspectionReportByOwnEngineerController ($window, $timeout, $scope, $stateParams, $uibModalInstance, entity, SelfCertificationThroughOwnEngineersByApplicationNo, FileManagement, SelfCertificationByOwnEngineer, downloadService) {
        var vm = this;
        vm.selfCertificationByOwnEngineer = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.downloadFile=downloadFile;
//        var filename ="";
//        var fileNameWithExtension = "";
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function downloadFile(file)
        {
            alert(file);
            var fileNameArray1 = file.split(".");
            var fileName = fileNameArray1[0];
            downloadService.download(file, fileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

    }
})();
