(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-service-form-field-data', {
            parent: 'entity',
            url: '/citizen-service-form-field-data',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceFormFieldData'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data.html',
                    controller: 'CitizenServiceFormFieldDataController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizen-service-form-field-data-detail', {
            parent: 'citizen-service-form-field-data',
            url: '/citizen-service-form-field-data/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceFormFieldData'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data-detail.html',
                    controller: 'CitizenServiceFormFieldDataDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CitizenServiceFormFieldData', function($stateParams, CitizenServiceFormFieldData) {
                    return CitizenServiceFormFieldData.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizen-service-form-field-data',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizen-service-form-field-data-detail.edit', {
            parent: 'citizen-service-form-field-data-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data-dialog.html',
                    controller: 'CitizenServiceFormFieldDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceFormFieldData', function(CitizenServiceFormFieldData) {
                            return CitizenServiceFormFieldData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-form-field-data.new', {
            parent: 'citizen-service-form-field-data',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data-dialog.html',
                    controller: 'CitizenServiceFormFieldDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                formFieldId: null,
                                formFieldData: null,
                                fieldName: null,
                                fieldTitle: null,
                                required: null,
                                options: null,
                                placeHolder: null,
                                fieldOrder: null,
                                fieldType: null,
                                applicationNo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizen-service-form-field-data', null, { reload: 'citizen-service-form-field-data' });
                }, function() {
                    $state.go('citizen-service-form-field-data');
                });
            }]
        })
        .state('citizen-service-form-field-data.edit', {
            parent: 'citizen-service-form-field-data',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data-dialog.html',
                    controller: 'CitizenServiceFormFieldDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceFormFieldData', function(CitizenServiceFormFieldData) {
                            return CitizenServiceFormFieldData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-form-field-data', null, { reload: 'citizen-service-form-field-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-form-field-data.delete', {
            parent: 'citizen-service-form-field-data',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-form-field-data/citizen-service-form-field-data-delete-dialog.html',
                    controller: 'CitizenServiceFormFieldDataDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CitizenServiceFormFieldData', function(CitizenServiceFormFieldData) {
                            return CitizenServiceFormFieldData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-form-field-data', null, { reload: 'citizen-service-form-field-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
