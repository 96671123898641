(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('access-token', {
            parent: 'entity',
            url: '/access-token',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'AccessTokens'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/access-token/access-tokens.html',
                    controller: 'AccessTokenController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('access-token-detail', {
            parent: 'access-token',
            url: '/access-token/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'AccessToken'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/access-token/access-token-detail.html',
                    controller: 'AccessTokenDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'AccessToken', function($stateParams, AccessToken) {
                    return AccessToken.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'access-token',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('access-token-detail.edit', {
            parent: 'access-token-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/access-token/access-token-dialog.html',
                    controller: 'AccessTokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccessToken', function(AccessToken) {
                            return AccessToken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('access-token.new', {
            parent: 'access-token',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/access-token/access-token-dialog.html',
                    controller: 'AccessTokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                keyName: null,
                                keyValue: null,
                                generatedOnDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('access-token', null, { reload: 'access-token' });
                }, function() {
                    $state.go('access-token');
                });
            }]
        })
        .state('access-token.edit', {
            parent: 'access-token',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/access-token/access-token-dialog.html',
                    controller: 'AccessTokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccessToken', function(AccessToken) {
                            return AccessToken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('access-token', null, { reload: 'access-token' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('access-token.delete', {
            parent: 'access-token',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/access-token/access-token-delete-dialog.html',
                    controller: 'AccessTokenDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AccessToken', function(AccessToken) {
                            return AccessToken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('access-token', null, { reload: 'access-token' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
