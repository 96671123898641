(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
        function stateConfig($stateProvider) {
     $stateProvider.state('dashboardbyservice', {
            parent: 'dashboard',
            url: '/dashboardbyservice?:servicename:serviceId:status',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard-by-service/dashboard-by-service.html',
                        controller: 'DashboardByServiceController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    resolve: {

                    }
                }).result.then(function() {
                    $state.go('dashboard', null, { reload: 'dashboard' });
                }, function() {
                    $state.go('dashboard');
                });
            }]
        })
    }
})();
