(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServiceFormAttachmentController', ServiceFormAttachmentController);

    ServiceFormAttachmentController.$inject = ['ServiceFormAttachment'];

    function ServiceFormAttachmentController(ServiceFormAttachment) {

        var vm = this;

        vm.serviceFormAttachments = [];

        loadAll();

        function loadAll() {
            ServiceFormAttachment.query(function(result) {
                vm.serviceFormAttachments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
