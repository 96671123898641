(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServiceFormAttachmentDialogController', ServiceFormAttachmentDialogController);

    ServiceFormAttachmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ServiceFormAttachment'];

    function ServiceFormAttachmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ServiceFormAttachment) {
        var vm = this;

        vm.serviceFormAttachment = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.serviceFormAttachment.id !== null) {
                ServiceFormAttachment.update(vm.serviceFormAttachment, onSaveSuccess, onSaveError);
            } else {
                ServiceFormAttachment.save(vm.serviceFormAttachment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
