(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('SelfCertificationByOwnEngineerDialogController', SelfCertificationByOwnEngineerDialogController);

    SelfCertificationByOwnEngineerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SelfCertificationByOwnEngineer'];

    function SelfCertificationByOwnEngineerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SelfCertificationByOwnEngineer) {
        var vm = this;

        vm.selfCertificationByOwnEngineer = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.selfCertificationByOwnEngineer.id !== null) {
                SelfCertificationByOwnEngineer.update(vm.selfCertificationByOwnEngineer, onSaveSuccess, onSaveError);
            } else {
                SelfCertificationByOwnEngineer.save(vm.selfCertificationByOwnEngineer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:selfCertificationByOwnEngineerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.detailOfsanctionedSinglelineDiagamDated = false;
        vm.datePickerOpenStatus.dateOfSelfCertification = false;
        vm.datePickerOpenStatus.applicationUploadedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
