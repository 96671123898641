(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServiceFormFieldController', ServiceFormFieldController);

    ServiceFormFieldController.$inject = ['$scope', 'ServiceFormField', 'Department', 'DepartmentSrvByDepartment', 'ServiceFormFieldByService'];

    function ServiceFormFieldController($scope, ServiceFormField, Department, DepartmentSrvByDepartment, ServiceFormFieldByService) {

        var vm = this;

        vm.serviceFormFields = [];

//        loadAll();
//
//        function loadAll() {
//            ServiceFormField.query(function(result) {
//                vm.serviceFormFields = result;
//
//                console.log(vm.serviceFormFields);
//
//                vm.searchQuery = null;
//            });
//        }

        loadDepartment();

                function loadDepartment() {
                    Department.query(function(result) {
                        vm.departments = result;
                        vm.searchQuery = null;
                    });

                }

                $scope.loadDeparments = function (departmentsObj) {
                                    vm.services = DepartmentSrvByDepartment.query({departmentId : departmentsObj.id});

                                    //console.log(vm.services);
                                }

                 $scope.loadServices = function (servicesObj) {

                                                    vm.serviceFormFields = ServiceFormFieldByService.query({departmentId : servicesObj.id});

                                                }



            }
})();
