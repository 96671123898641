(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenServiceDetailsController', CitizenServiceDetailsController);

    CitizenServiceDetailsController.$inject = ['CitizenServiceDetails', 'CitizenServiceDetailsByAppNo'];

    function CitizenServiceDetailsController(CitizenServiceDetails, CitizenServiceDetailsByAppNo) {

        var vm = this;
        vm.getData = getData;
//        vm.citizenServiceDetails = [];
        function getData(appNo)
        {
           // alert(appNo);
            CitizenServiceDetailsByAppNo.get({pinCode: appNo}, function(result){
            vm.citizenServiceDetails = result;
            //                vm.searchQuery = null;
            })
        }

//        loadAll();
//
//        function loadAll() {
//            CitizenServiceDetails.query(function(result) {
//                vm.citizenServiceDetails = result;
//                vm.searchQuery = null;
//            });
//        }
    }
})();
