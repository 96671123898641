(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('CitizenServiceDetailsByAppNo', CitizenServiceDetailsByAppNo);

    CitizenServiceDetailsByAppNo.$inject = ['$resource', 'DateUtils'];

    function CitizenServiceDetailsByAppNo ($resource, DateUtils) {
        var resourceUrl =  'api/citizen-service-details-by-pin/:pinCode';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.appliedOnDate = DateUtils.convertDateTimeFromServer(data.appliedOnDate);
                        data.assignedOnDate = DateUtils.convertDateTimeFromServer(data.assignedOnDate);
                        data.formFilledOnDate = DateUtils.convertDateTimeFromServer(data.formFilledOnDate);
                        data.paymentMadeOnDate = DateUtils.convertDateTimeFromServer(data.paymentMadeOnDate);
                        data.rejectedOnDate = DateUtils.convertDateTimeFromServer(data.rejectedOnDate);
                        data.clearanceGivenOnDate = DateUtils.convertDateTimeFromServer(data.clearanceGivenOnDate);
                        data.requiredOnDate = DateUtils.convertDateTimeFromServer(data.requiredOnDate);
                        data.deactivatedOnDate = DateUtils.convertDateTimeFromServer(data.deactivatedOnDate);
                        data.notRequiredOnDate = DateUtils.convertDateTimeFromServer(data.notRequiredOnDate);
                        data.lastCommentedDate = DateUtils.convertDateTimeFromServer(data.lastCommentedDate);
                        data.charteredAssignOnDate = DateUtils.convertDateTimeFromServer(data.charteredAssignOnDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
