(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DepartmentDialogController', DepartmentDialogController);

    DepartmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Government', 'Department'];

    function DepartmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Government, Department) {
        var vm = this;

        vm.department = entity;
        vm.clear = clear;
        vm.save = save;

        loadAll();

        function loadAll() {
            Government.query(function(result) {
                vm.governments = result;
                vm.searchQuery = null;
            });
        }

         load();
                          function load () {
                                       vm.id = vm.department.governmentId;
                                      // alert(vm.id);
                                       Government.get({id: vm.id},function(result){
                                             vm.districtvalue=result;
                                             $scope.selectedGovernment = {id: result.id, governmentName: result.governmentName}
                                                        });
                                        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.department.governmentId = $scope.selectedGovernment.id;
            if (vm.department.id !== null) {
                Department.update(vm.department, onSaveSuccess, onSaveError);
            } else {
                Department.save(vm.department, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:departmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
