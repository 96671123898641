(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('transferApplicationController', transferApplicationController);

    transferApplicationController.$inject = ['$scope', '$stateParams', 'Principal', 'LoginService', '$state', '$uibModalInstance', 'citizenServiceLog', 'CitizenServiceLog', 'citizenServiceDetail', 'CitizenServiceDetails'];
    function transferApplicationController ($scope, $stateParams, Principal, LoginService, $state, $uibModalInstance, citizenServiceLog, CitizenServiceLog, citizenServiceDetail, CitizenServiceDetails) {
         var vm = this;
         vm.account = null;
         vm.isAuthenticated = null;

         vm.login = LoginService.open;
         vm.register = register;
         vm.clear = clear;
         vm.transferApplication = transferApplication;
         vm.citizenServiceLog = citizenServiceLog;
         vm.citizenServiceDetail = citizenServiceDetail;

         var citizenServiceLogid = null;
         var citizenServiceAttachmentObject;

         function clear () {
             $uibModalInstance.dismiss('cancel');

         }
         $scope.$on('authenticationSuccess', function() {
             getAccount();
         });

         getAccount();

         function getAccount() {
             Principal.identity().then(function(account) {
                 vm.account = account;
                 vm.isAuthenticated = Principal.isAuthenticated;
             });
         }

         function register () {
             $state.go('register');
         }

         function transferApplication(){
             vm.citizenServiceLog.citizenId = $stateParams.citizenId;
             vm.citizenServiceLog.serviceId = $stateParams.serviceId;
             vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
             vm.citizenServiceLog.actionTaken = "Application Transfered";
             CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
         }

         function onSaveServiceLogSuccess(result)
         {
             $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
             vm.citizenServiceDetail.lastComment="Application Transfered";
             CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);
         }
         function onUpdateCitizenServiceDetailSuccess(result)
         {
             $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
             clear();

         }
         function onUpdateCitizenServiceDetailError()
         {
             vm.isSaving = false;
         }

         function onSaveServiceLogError()
         {
             vm.isSaving = false;
         }

    }
})();
