(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ceseInstallationTestedReportStatController', ceseInstallationTestedReportStatController);

    ceseInstallationTestedReportStatController.$inject = ['$scope','entity'];

    function ceseInstallationTestedReportStatController($scope, entity) {

        var vm = this;
        vm.installationTestedByAllCESE = entity;

//        console.log(vm.installationTestedByAllCESE);

//        loadAll();
//
//        function loadAll() {
//            InstallationTestedByAllCESE.query(function(result) {
//                vm.installationTestedByCESE = result;
//                vm.searchQuery = null;
//            });
//        }
//
//        $scope.setPageSize = function (pageSizeVal) {
//
//            $scope.pageSize = pageSizeVal;
//
//        }
//
//        $scope.pageChangeHandler = function(num) {
//
//        };
    }
})();
