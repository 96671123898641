(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CeseElectricalInstallationDetailsDetailController', CeseElectricalInstallationDetailsDetailController);

    CeseElectricalInstallationDetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CeseElectricalInstallationDetails'];

    function CeseElectricalInstallationDetailsDetailController($scope, $rootScope, $stateParams, previousState, entity, CeseElectricalInstallationDetails) {
        var vm = this;

        vm.ceseElectricalInstallationDetails = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:ceseElectricalInstallationDetailsUpdate', function(event, result) {
            vm.ceseElectricalInstallationDetails = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
