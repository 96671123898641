(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('inspectionReportByOwnEngineerController', inspectionReportByOwnEngineerController);

    inspectionReportByOwnEngineerController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SelfCertificationByOwnEngineer', 'FileManagement','citizenServiceLog', 'CitizenServiceLog'];

    function inspectionReportByOwnEngineerController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SelfCertificationByOwnEngineer, FileManagement, citizenServiceLog, CitizenServiceLog) {
        var vm = this;

        vm.selfCertificationByOwnEngineer = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.doubleextension = true;
        vm.citizenServiceLog = citizenServiceLog;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function loadAll() {
            SelfCertificationByOwnEngineer.query(function(result) {
                vm.selfCertificationByOwnEngineers = result;
                vm.searchQuery = null;
            });
        }

        function save () {

            saveServiceLog();

        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:selfCertificationByOwnEngineerUpdate', result);
//            $scope.$emit('eServicesApp:notificationUpdate', result);
            $uibModalInstance.close(result);
            alert("saved");
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.detailOfsanctionedSinglelineDiagamDated = false;
        vm.datePickerOpenStatus.dateOfSelfCertification = false;
        vm.datePickerOpenStatus.applicationUploadedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

       function onUploadAttachmentError () {
           vm.isSaving = false;
       }
       function saveServiceLog(){

           vm.citizenServiceLog.actionTaken = "Self Certification Uploaded by Own Engineer.";
           vm.citizenServiceLog.comment = "Self Certification Uploaded by Own Engineer.";
           vm.citizenServiceLog.citizenId = $stateParams.citizenId;
           vm.citizenServiceLog.serviceId = $stateParams.serviceId;
           vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
           CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);

//           vm.citizenServiceLog.actionTaken = "Open Comment";
//           console.log(vm.citizenServiceLog);
//           CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
       }

       function onSaveServiceLogSuccess(result)
       {
          vm.isSaving = true;
          var isFileValid = true;
          var file ="";
          var inputFileNamewithextension1 = "";
          var inputFileNamewithextension2 = "";
          var filename = "";
          var fileNameArray1 = vm.serviceFormAttachemnt.file1.name.split(".");
          var fileextension1 = "";
          if ((fileNameArray1.length < 3)) {
              if (fileNameArray1[0] != "") {
                  if ((fileNameArray1.length > 1)) {
                      fileextension1 = fileNameArray1[(fileNameArray1.length - 1)];
                  }
                  var inputFileName1 = $stateParams.applicationNo +"-Form-I";
                  if (fileextension1 == "pdf" || fileextension1 == "PDF") {
                        inputFileNamewithextension1 = inputFileName1 + "." + fileextension1;
                      filename = inputFileName1;
                      file =vm.serviceFormAttachemnt.file1;
                      FileManagement.saveFile(file,filename);
                  }
              }
              else {
                  isFileValid = false;
                  alert("File-I should be PDF only");
              }
          }
          else {
              alert("Please select correct file format");
              vm.doubleextension = false;
          }

          var fileNameArray2 = vm.serviceFormAttachemnt.file2.name.split(".");
          var fileextension2 = "";
          if ((fileNameArray2.length < 3)) {
              if (fileNameArray2[0] != "") {
                  if ((fileNameArray2.length > 1)) {
                      fileextension2 = fileNameArray2[(fileNameArray2.length - 1)];
                  }
                  var inputFileName2 = $stateParams.applicationNo +"-Form-II";
                  if (fileextension2 == "pdf" || fileextension1 == "PDF") {
                        inputFileNamewithextension2 = inputFileName2 + "." + fileextension2;
                      filename = inputFileName2;
                      file =vm.serviceFormAttachemnt.file1;
                      FileManagement.saveFile(file,filename);
                  }
              }
              else {
                  isFileValid = false;
                  alert("File-II should be PDF only");
              }
          }
          else {
              alert("Please select correct file format");
              vm.doubleextension = false;
          }
              if(isFileValid == true)
              {
              if (vm.selfCertificationByOwnEngineer.id !== null) {
                  SelfCertificationByOwnEngineer.update(vm.selfCertificationByOwnEngineer, onSaveSuccess, onSaveError);
              } else {
                  vm.selfCertificationByOwnEngineer.uploadedFormI = inputFileNamewithextension1;
                  vm.selfCertificationByOwnEngineer.attachmentForm1title = "Form-I";
                  vm.selfCertificationByOwnEngineer.uploadedFormII = inputFileNamewithextension2;
                  vm.selfCertificationByOwnEngineer.attachmentForm2Title = "Form-II";
                  vm.selfCertificationByOwnEngineer.applicationNo = $stateParams.applicationNo;
                  vm.selfCertificationByOwnEngineer.serviceId = $stateParams.serviceId
                  SelfCertificationByOwnEngineer.save(vm.selfCertificationByOwnEngineer, onSaveSuccess, onSaveError);
              }
          }
       }
       function onSaveServiceLogError(result)
       {

           vm.isSaving = false;

       }
    }
})();
