(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('GovernmentController', GovernmentController);

    GovernmentController.$inject = ['Government'];

    function GovernmentController(Government) {

        var vm = this;

        vm.governments = [];

        loadAll();

        function loadAll() {
            Government.query(function(result) {
                vm.governments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
