(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenServiceDetailsDialogController', CitizenServiceDetailsDialogController);

    CitizenServiceDetailsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CitizenServiceDetails'];

    function CitizenServiceDetailsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CitizenServiceDetails) {
        var vm = this;

        vm.citizenServiceDetails = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.citizenServiceDetails.id !== null) {
                CitizenServiceDetails.update(vm.citizenServiceDetails, onSaveSuccess, onSaveError);
            } else {
                CitizenServiceDetails.save(vm.citizenServiceDetails, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:citizenServiceDetailsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.appliedOnDate = false;
        vm.datePickerOpenStatus.assignedOnDate = false;
        vm.datePickerOpenStatus.formFilledOnDate = false;
        vm.datePickerOpenStatus.paymentMadeOnDate = false;
        vm.datePickerOpenStatus.rejectedOnDate = false;
        vm.datePickerOpenStatus.clearanceGivenOnDate = false;
        vm.datePickerOpenStatus.requiredOnDate = false;
        vm.datePickerOpenStatus.deactivatedOnDate = false;
        vm.datePickerOpenStatus.notRequiredOnDate = false;
        vm.datePickerOpenStatus.lastCommentedDate = false;
        vm.datePickerOpenStatus.charteredAssignOnDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
