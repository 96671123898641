
(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('otpservice',['$http', function($http){
        return {
                save: function(otp,desfilename, srcfilename, citizenServiceLogid){
                    var resourceUrl =  'api/verifyotp';
                    var fd = new FormData();
                    fd.append('otp', otp);
                    fd.append('desfilename',desfilename);
                    fd.append('srcfilename',srcfilename);
                    fd.append('citizenServiceLogid',citizenServiceLogid);

                    $http.post(resourceUrl, fd, {
                    transformRequest : angular.identity,
                    headers : {
                    'Content-Type' : undefined
                    }
                    }).success(function() {
                    console.log('success');
                    return "success";
                    }).error(function() {
                    console.log('error');
                    return "error";
                    });
                }
            }
        }]);
})();

//(function() {
//    'use strict';
//    angular
//        .module('eServicesApp')
//        .factory('otpservice', otpservice);
//
//    otpservice.$inject = ['$resource'];
//
//    function otpservice ($resource) {
//        var resourceUrl =  'api/verifyotp/:otp/:resultId/:accountId';
//
//        return $resource(resourceUrl, {}, {
//            'query': { method: 'GET', isArray: true},
//            'get': {
//                method: 'GET',
//                transformResponse: function (data) {
//                    if (data) {
//                        data = angular.fromJson(data);
//                    }
//                    return data;
//                }
//            }
//        });
//    }
//})();


