(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('servicePaymentDialogController', servicePaymentDialogController);

    servicePaymentDialogController.$inject = ['Principal','$timeout','$scope','$stateParams','$uibModalInstance','Payment'];
    function servicePaymentDialogController (Principal,$timeout, $scope, $stateParams, $uibModalInstance, Payment) {
       var vm = this;

       vm.account = null;
       vm.isAuthenticated = null;
//       vm.login = LoginService.open;
       vm.register = register;
       vm.clear = clear;
       vm.citizenServicePayment = $stateParams.feeAmount;

       vm.paymentparameters = Payment.get({citizenServiceFeeId:$stateParams.id});

       function clear () {
           $uibModalInstance.dismiss('cancel');
       }
       $scope.$on('authenticationSuccess', function() {
           getAccount();
       });

       getAccount();

       function getAccount() {
           Principal.identity().then(function(account) {
               vm.account = account;
               vm.isAuthenticated = Principal.isAuthenticated;
           });
       }
       function register () {
           $state.go('register');
       }

    }
})();
