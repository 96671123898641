(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
        function stateConfig($stateProvider) {
        $stateProvider.state('dashboard', {
            parent: 'app',
            url: '/dashboard',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/dashboard/dashboard.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                serviceStatistics: ['$stateParams', 'ServiceStatistics', function($stateParams, ServiceStatistics) {
                    return ServiceStatistics.get({id : $stateParams.id}).$promise;
                }],
                serviceWiseBriefDashboard: ['$stateParams', 'ServiceWiseBriefDashboard', function($stateParams, ServiceWiseBriefDashboard) {
                    return ServiceWiseBriefDashboard.query({id : $stateParams.id}).$promise;
                }]
            }
        });
    }
})();
