(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('services-required-by-hepc-user', {
            parent: 'entity',
            url: '/services-required-by-hepc-user',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ServicesRequiredByHEPCUsers'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-users.html',
                    controller: 'ServicesRequiredByHEPCUserController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('services-required-by-hepc-user-detail', {
            parent: 'services-required-by-hepc-user',
            url: '/services-required-by-hepc-user/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ServicesRequiredByHEPCUser'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-user-detail.html',
                    controller: 'ServicesRequiredByHEPCUserDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ServicesRequiredByHEPCUser', function($stateParams, ServicesRequiredByHEPCUser) {
                    return ServicesRequiredByHEPCUser.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'services-required-by-hepc-user',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('services-required-by-hepc-user-detail.edit', {
            parent: 'services-required-by-hepc-user-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-user-dialog.html',
                    controller: 'ServicesRequiredByHEPCUserDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServicesRequiredByHEPCUser', function(ServicesRequiredByHEPCUser) {
                            return ServicesRequiredByHEPCUser.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('services-required-by-hepc-user.new', {
            parent: 'services-required-by-hepc-user',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-user-dialog.html',
                    controller: 'ServicesRequiredByHEPCUserDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                uname: null,
                                investorname: null,
                                projectid: null,
                                serviceid: null,
                                projectserviceid: null,
                                requestType: null,
                                status: null,
                                applicationNo: null,
                                cafPin: null,
                                appliedDate: null,
                                projectSiteDistrict: null,
                                userEmail: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('services-required-by-hepc-user', null, { reload: 'services-required-by-hepc-user' });
                }, function() {
                    $state.go('services-required-by-hepc-user');
                });
            }]
        })
        .state('services-required-by-hepc-user.edit', {
            parent: 'services-required-by-hepc-user',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-user-dialog.html',
                    controller: 'ServicesRequiredByHEPCUserDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServicesRequiredByHEPCUser', function(ServicesRequiredByHEPCUser) {
                            return ServicesRequiredByHEPCUser.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('services-required-by-hepc-user', null, { reload: 'services-required-by-hepc-user' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('services-required-by-hepc-user.delete', {
            parent: 'services-required-by-hepc-user',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/services-required-by-hepc-user/services-required-by-hepc-user-delete-dialog.html',
                    controller: 'ServicesRequiredByHEPCUserDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ServicesRequiredByHEPCUser', function(ServicesRequiredByHEPCUser) {
                            return ServicesRequiredByHEPCUser.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('services-required-by-hepc-user', null, { reload: 'services-required-by-hepc-user' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
