(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('citizenCommentController', citizenCommentController);

    citizenCommentController.$inject = ['$window', '$scope', '$stateParams', 'Principal', 'LoginService', '$state', '$uibModalInstance', 'citizenServiceLog', 'CitizenServiceLog', 'citizenServiceDetail', 'CitizenServiceDetails', 'citizenServiceAttachment', 'CitizenServiceLogAttachment', 'FileManagement', 'CitizenServiceCompleteDetails','Citizen', 'Notification','CheckContent'];

    function citizenCommentController ($window, $scope, $stateParams, Principal, LoginService, $state, $uibModalInstance, citizenServiceLog, CitizenServiceLog, citizenServiceDetail, CitizenServiceDetails, citizenServiceAttachment, CitizenServiceLogAttachment, FileManagement, CitizenServiceCompleteDetails , Citizen, Notification, CheckContent) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.citizenServiceDetail = null;
        vm.serviceAttachemnt = {};
        vm.login = LoginService.open;
        vm.register = register;
        vm.notification = {};
        vm.clear = clear;
        vm.citizenServiceLog = citizenServiceLog;
        vm.saveComments = saveComments;
        vm.citizenServiceDetail = citizenServiceDetail[0];
        vm.citizenServiceAttachment = citizenServiceAttachment;
        var citizenServiceLogid = null;
        var citizenServiceAttachmentObject;

        function clear () {
            $uibModalInstance.dismiss('cancel');
//            $window.history.back();

        }
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                 vm.login = account.login;
                 // alert(vm.login);
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }

        function saveComments(){
            if(vm.serviceAttachemnt.file!=null){
                if(CheckContent.checkFile(vm.serviceAttachemnt.file.name)){
                    saveMethod();
                }else alert('Invalid File');
            }else saveMethod();
        }

        function saveMethod() {
            vm.citizenServiceLog.citizenId = $stateParams.citizenId;
            vm.citizenServiceLog.serviceId = $stateParams.serviceId;
            vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;

            Citizen.get({id:$stateParams.citizenId},function(result){
                vm.loginName = result.loginName;
                vm.notification.application_number = $stateParams.applicationNo;
                vm.notification.action_taken_on = vm.loginName;
                vm.notification.action_taken_by = vm.login;
                vm.notification.action_taken_subject = "Comment On Service";
                vm.notification.action_taken_detail = "Comment On Service Detail";
                Notification.save(vm.notification, onSaveSuccess, onSaveError);
            })
            vm.citizenServiceLog.actionTaken = "Open Comment";
            console.log(vm.citizenServiceLog);
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
        }

        function onSaveServiceLogSuccess(result)
        {
            $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
            citizenServiceLogid=result.id;
            vm.citizenServiceDetail.lastComment="Open Comment";
            CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);
        }

        function onUpdateCitizenServiceDetailSuccess(result)
        {
            $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
            if(angular.isDefined(vm.serviceAttachemnt.file))
            {
                saveServiceAttachment();
            }
            else
            {
               $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onUpdateCitizenServiceDetailError()
        {
            vm.isSaving = false;
        }

        function onSaveServiceLogError()
        {
            vm.isSaving = false;
        }

        function saveServiceAttachment()
        {
            var file=vm.serviceAttachemnt.file;
            vm.citizenServiceAttachment.serviceLogId=citizenServiceLogid;
            vm.citizenServiceAttachment.attachmentFile=file.name;
            vm.citizenServiceAttachment.attachmentTitle="Open Comment";
            vm.citizenServiceAttachment.fileExtension =file;
            CitizenServiceLogAttachment.update(vm.citizenServiceAttachment,onSaveServiceAttachmentSuccess,onSaveServiceAttachmentError);
        }

        function onSaveServiceAttachmentSuccess(result)
        {
            $scope.$emit('eServicesApp:serviceAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.serviceAttachemnt.file;
            FileManagement.saveFile(file,filename);
            vm.isSaving = false;
            citizenServiceAttachmentObject=result;
           var fileNameArray = vm.serviceAttachemnt.file.name.split(".");
           var fileextension="";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName=result.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           citizenServiceAttachmentObject.attachmentFile=inputFileName;
           citizenServiceAttachmentObject.attachmentExtension = fileextension;
           CitizenServiceLogAttachment.update(citizenServiceAttachmentObject, onUpdateServiceAttachmentSuccess, onUpdateServiceAttachmentError);

        }

        function onSaveServiceAttachmentError()
        {
            vm.isSaving = false;
        }

        function onUpdateServiceAttachmentSuccess()
        {
            vm.isSaving = false;
           // clear();
        }

        function onUpdateServiceAttachmentError()
        {
            vm.isSaving = false;
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:notificationUpdate', result);
            $uibModalInstance.close(result);
            alert("saved");
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
