(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('StateDialogController', StateDialogController);

    StateDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'Country', 'entity', 'State'];

    function StateDialogController ($timeout, $scope, $state, $stateParams, Country, entity, State) {
        var vm = this;

        vm.state = entity;
        vm.clear = clear;
        vm.save = save;



        loadAll();

        function loadAll() {
            Country.query(function(result) {
                vm.countries = result;
                vm.searchQuery = null;
                /*console.log(vm.countries);*/
            });
        }

         load();
         function load () {
                       vm.id = vm.state.countryId;
                          Country.get({id: vm.id},function(result){
                          vm.countryvalue=result;

                          $scope.selected = {id: result.id, countryName: result.countryName}
                                });
                }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('state', null, { reload: 'state' });
        }

        function save () {
            vm.isSaving = true;
            vm.state.countryId = $scope.selected.id;
            if (vm.state.id !== null) {
                State.update(vm.state, onSaveSuccess, onSaveError);
            } else {
                State.save(vm.state, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:stateUpdate', result);
            $state.go('state', null, { reload: 'state' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
