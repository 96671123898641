(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('serviceclearanceaction', {
            parent: 'app',
            url: '/service-in-process?:status:fromDate:toDate',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/service-clearance-action/serviceclearanceactions.html',
                    controller: 'serviceClearanceActionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
//                    serviceStatistics: ['$stateParams', 'ServiceInProcessStatistics', function($stateParams, ServiceInProcessStatistics) {
//                        return ServiceInProcessStatistics.get({id : $stateParams.id}).$promise;
//                    }],


                    allServicesInProcess: ['$stateParams', 'AllServicesInProcess', function($stateParams, AllServicesInProcess) {
                        if($stateParams.status==="Pending" || $stateParams.status==="New")
                        {
                            return AllServicesInProcess.query({status : $stateParams.status}).$promise;
                        }
                        else
                        {
                            return null;
                        }
                    }]
            }
        })

    .state('accept-service', {
        parent: 'serviceclearanceaction',
        url: '/accept-service?:citizenId:serviceId:applicationNo',
        data: {
            authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER', 'ROLE_NODAL_LICENSE']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/service-clearance-action/accept-service-dialog.html',
                controller: 'acceptServiceDialog',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {

                citizenServiceLog: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        comment: null,
                        id: null
                    };

                },
                citizenServiceAttachment: function () {
                    return {
                        serviceLogId: null,
                        attachmentTitle: null,
                        attachmentFile: null,
                        attachmentExtension: null,
                        id: null
                    };
                },
                citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                    return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                }]

                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })

    .state('citizenservicefeeupdated', {
        parent: 'serviceclearanceaction',
        url: '/citizenservicefeeupdated?:citizenId:serviceId:applicationNo',
        data: {
            authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER', 'ROLE_NODAL_LICENSE']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/service-clearance-action/citizenservicefeeupdated.html',
                controller: 'citizenServiceFeeUpdatedController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    citizenServiceFee: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                feeFor: null,
                                feeAmount: null,
                                isPaid: null,
                                transactionId: null,
                                feeSuggestedBy: null,
                                feeSugestedOnDate: null,
                                feePaidBy: null,
                                feePaidOnDate: null,
                                serviceFeeResponse: null,
                                comment: null,
                                applicationNo: null,
                                id: null
                            };
                        }
                    }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })

    .state('serviceForm-view', {
        parent: 'serviceclearanceaction',
        url: '/fillform?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_NODAL_LICENSE','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
           },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
               $uibModal.open({
                   templateUrl: 'app/service-clearance-action/serviceFormFilled.html',
                   controller: 'serviceFormFilledController',
                   controllerAs: 'vm',
                   backdrop: 'static',
                   size: 'lg',
                   resolve: {
                        departmentSrv: ['DepartmentSrv', function(DepartmentSrv) {
                            return DepartmentSrv.get({id : $stateParams.serviceId}).$promise;
                        }]

                    }
               }).result.then(function() {
                   $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
               }, function() {
                   $state.go('serviceclearanceaction');
               });
            }]

    })

    .state('servicePayment', {
          parent: 'serviceclearanceaction',
          url: '/payFee/{projectService:json}',
          data: {
              authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_USER']
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/serviceclearanceactions/servicePayment.html',
                  controller: 'servicePaymentController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                      projectServicePayment: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              paymentMade: null,
                              paymentMadeBy: null,
                              paymentDate: null,
                              id: null
                          };
                      },
                      projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        paymentparameters: ['Payment', function(Payment) {
                            return Payment.get({projectid:$stateParams.projectService.projectid,amount:$stateParams.projectService.serviceFee,serviceid:$stateParams.projectService.serviceid}).$promise;
                        }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('home');
                          return $translate.refresh();
                      }]
                  }
              }).result.then(function() {
                  $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
              }, function() {
                  $state.go('serviceclearanceaction');
              });
          }]
      })

    .state('verifyPayment', {
            parent: 'serviceclearanceaction',
            url: '/verify/{projectService:json}',
            data: {
                authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_ACCOUNT_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/verifyPayment.html',
                    controller: 'verifyPaymentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                          },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })


    .state('serviceLog', {
        parent: 'serviceclearanceaction',
        url: '/serviceLog?:applicationNo',
        data: {
            authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_USER','ROLE_RM','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_NODAL_LICENSE','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/citizen-dashboard/service-log-dialog.html',
                controller: 'serviceLogDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
               entity: ['CitizenServiceLogDetailsByApplicationNo', function(CitizenServiceLogDetailsByApplicationNo) {
                    return CitizenServiceLogDetailsByApplicationNo.query({applicationNo:$stateParams.applicationNo});
                }]
                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('^');
            });
        }]
    })

    .state('service-Clearance', {
          parent: 'serviceclearanceaction',
          url: '/clearance?:citizenId:serviceId:applicationNo',
          data: {
              authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER','ROLE_NODAL_LICENSE']
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/service-clearance-action/serviceclearance.html',
                  controller: 'serviceClearanceController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {

                    citizenServiceLog: function () {
                        return {
                            citizenId: null,
                            serviceId: null,
                            actionTaken: null,
                            actionTakenBy: null,
                            actionTakenDate: null,
                            comment: null,
                            applicationNo: null,
                            id: null
                        };

                    },
                    citizenServiceAttachment: function () {
                        return {
                            serviceLogId: null,
                            attachmentTitle: null,
                            attachmentFile: null,
                            attachmentExtension: null,
                            applicationNo: null,
                            id: null
                        };
                    },
                    citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                        return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                    }]


                  }
              }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
          }]
      })
  .state('deemedClearance', {
            parent: 'serviceclearanceaction',
            url: '/deemed/{projectService:json}',
            data: {
                authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_DESK_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/deemedClearance.html',
                    controller: 'deemedClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                              fileName: null,
                              description: null,
                              fileExtension: null,
                              serverFileName: null,
                              id: null
                        };
                        },
                        projectServiceLog: function () {
                            return {
                              projectid: null,
                              serviceid: null,
                              comments: null,
                              commentDate: null,
                              commentByUserLogin: null,
                              actionTaken: null,
                              id: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
  .state('comment-dialog', {
      parent: 'serviceclearanceaction',
      url: '/comment?:citizenId:serviceId:applicationNo',
      data: {
          authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER','ROLE_NODAL_LICENSE']
      },
      onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
          $uibModal.open({
              templateUrl: 'app/citizen-dashboard/comment-dialog.html',
              controller: 'citizenCommentController',
              controllerAs: 'vm',
              backdrop: 'static',
              size: 'lg',
              resolve: {

                  citizenServiceLog: function () {
                      return {
                          citizenId: null,
                          serviceId: null,
                          actionTaken: null,
                          actionTakenBy: null,
                          actionTakenDate: null,
                          comment: null,
                          id: null
                      };

                  },
                  citizenServiceAttachment: function () {
                      return {
                          serviceLogId: null,
                          attachmentTitle: null,
                          attachmentFile: null,
                          attachmentExtension: null,
                          id: null
                      };
                  },
                  citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                      return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                  }]

              }
         }).result.then(function() {
             $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
         }, function() {
             $state.go('^');
         });
      }]
    })
  .state('reject-dialog', {
        parent: 'serviceclearanceaction',
        url: '/reject?:citizenId:serviceId:applicationNo',
        data: {
            authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER', 'ROLE_NODAL_LICENSE']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/service-clearance-action/rejectDialog.html',
                controller: 'rejectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    citizenServiceLog: function () {
                        return {
                            citizenId: null,
                            serviceId: null,
                            actionTaken: null,
                            actionTakenBy: null,
                            actionTakenDate: null,
                            comment: null,
                            applicationNo: null,
                            id: null
                        };

                    },
                    citizenServiceAttachment: function () {
                        return {
                            serviceLogId: null,
                            attachmentTitle: null,
                            attachmentFile: null,
                            attachmentExtension: null,
                            applicationNo: null,
                            id: null
                        };
                    },
                    citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                        return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                    }]


                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })
    .state('notRequired', {
        parent: 'serviceclearanceaction',
        url: '/notrequired?:citizenId:serviceId:applicationNo',
        data: {
            authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER', 'ROLE_NODAL_LICENSE']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/service-clearance-action/not-required-dialog.html',
                controller: 'notRequiredDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    citizenServiceLog: function () {
                        return {
                            citizenId: null,
                            serviceId: null,
                            actionTaken: null,
                            actionTakenBy: null,
                            actionTakenDate: null,
                            comment: null,
                            applicationNo: null,
                            id: null
                        };

                    },
                    citizenServiceAttachment: function () {
                        return {
                            serviceLogId: null,
                            attachmentTitle: null,
                            attachmentFile: null,
                            attachmentExtension: null,
                            applicationNo: null,
                            id: null
                        };
                    },
                    citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                        return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                    }]


                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })

    .state('transfer-application', {
        parent: 'serviceclearanceaction',
        url: '/TransferApplication?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_NODAL_OFFICER','ROLE_NODAL_LICENSE']
           },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
               $uibModal.open({
                   templateUrl: 'app/service-clearance-action/transfer-application-dialog.html',
                   controller: 'transferApplicationController',
                   controllerAs: 'vm',
                   backdrop: 'static',
                   size: 'lg',
                   resolve: {
                        citizenServiceLog: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                actionTaken: null,
                                actionTakenBy: null,
                                actionTakenDate: null,
                                comment: null,
                                applicationNo: null,
                                id: null
                            };

                        },
                        citizenServiceDetail: ['TransferApplication', function(TransferApplication) {
                            return TransferApplication.get({pinCode : $stateParams.applicationNo}).$promise;
                        }]

                    }
               }).result.then(function() {
                   $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
               }, function() {
                   $state.go('serviceclearanceaction');
               });
            }]

    })



}
})();
