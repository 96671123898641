(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-service-log', {
            parent: 'entity',
            url: '/citizen-service-log',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceLogs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-logs.html',
                    controller: 'CitizenServiceLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizen-service-log-detail', {
            parent: 'citizen-service-log',
            url: '/citizen-service-log/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceLog'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-log-detail.html',
                    controller: 'CitizenServiceLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CitizenServiceLog', function($stateParams, CitizenServiceLog) {
                    return CitizenServiceLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizen-service-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizen-service-log-detail.edit', {
            parent: 'citizen-service-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-log-dialog.html',
                    controller: 'CitizenServiceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceLog', function(CitizenServiceLog) {
                            return CitizenServiceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-log.new', {
            parent: 'citizen-service-log',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-log-dialog.html',
                    controller: 'CitizenServiceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                actionTaken: null,
                                actionTakenBy: null,
                                actionTakenDate: null,
                                comment: null,
                                applicationNo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log', null, { reload: 'citizen-service-log' });
                }, function() {
                    $state.go('citizen-service-log');
                });
            }]
        })
        .state('citizen-service-log.edit', {
            parent: 'citizen-service-log',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-log-dialog.html',
                    controller: 'CitizenServiceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceLog', function(CitizenServiceLog) {
                            return CitizenServiceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log', null, { reload: 'citizen-service-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-log.delete', {
            parent: 'citizen-service-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log/citizen-service-log-delete-dialog.html',
                    controller: 'CitizenServiceLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CitizenServiceLog', function(CitizenServiceLog) {
                            return CitizenServiceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log', null, { reload: 'citizen-service-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
