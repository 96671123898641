(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('User_kyc_verificationDeleteController',User_kyc_verificationDeleteController);

    User_kyc_verificationDeleteController.$inject = ['$uibModalInstance', 'entity', 'User_kyc_verification'];

    function User_kyc_verificationDeleteController($uibModalInstance, entity, User_kyc_verification) {
        var vm = this;

        vm.user_kyc_verification = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            User_kyc_verification.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
