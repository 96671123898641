(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal'];

    function PasswordController (Auth, Principal) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.currentNotMatch = false;
        vm.showError = false;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword (currentPassword) {
            var encPassword = encryptPassword(currentPassword);
            Auth.checkPassword({
                username: vm.account.login,
                password: encPassword
            },function(result) {
                if(result) {
                    vm.currentNotMatch =false;
                    if (vm.password !== vm.confirmPassword) {
                        vm.error = null;
                        vm.success = null;
                        vm.doNotMatch = 'ERROR';
                    } else {
                        vm.doNotMatch = null;
                        var newPassword = encryptPassword(vm.password);
                        Auth.changePassword(newPassword).then(function () {
                            vm.error = null;
                            vm.success = 'OK';
                        }).catch(function () {
                            vm.success = null;
                            vm.error = 'ERROR';
                        });
                    }
                } else {
                    vm.currentNotMatch = true;
                }
            });
        }

        function encryptPassword(plainString) {
            var aesUtil;
            var iv = "b508d555ef6199ed5ced97caf0ff22cb";
            var salt = "b936454f90e756b033eb21aca9a88b66";
            var keySize = 128;
            var iterationCount = 10000;
            var passPhrase = "NAimIodPNkqOyHQXmEpoFA==Sdty=jHY";
            aesUtil = new AesUtil(keySize, iterationCount);
            var encryptedString = aesUtil.encrypt(salt, iv, passPhrase, plainString);
            return encryptedString;
        }
    }
})();
