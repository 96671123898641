(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('applicantDetailDialog', applicantDetailDialog);

    applicantDetailDialog.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','citizen'];
    function applicantDetailDialog ($timeout, $scope, $stateParams, $uibModalInstance, citizen) {
        var vm = this;
        vm.clear = clear;
        vm.citizen = citizen;
        console.log(vm.citizen);
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
