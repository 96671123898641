(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DetailOfApprovalController', DetailOfApprovalController);

    DetailOfApprovalController.$inject = ['$scope','$state','PreviousTwoYearData'];

    function DetailOfApprovalController ($scope, $state, PreviousTwoYearData) {
        var vm = this;

        loadAll();

        function loadAll() {
            PreviousTwoYearData.query(function(result) {
                vm.previousTwoYearData = result;
                vm.searchQuery = null;
            });
        }
    }
})();
