(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('UpdatesController', UpdatesController);

    UpdatesController.$inject = ['Updates'];

    function UpdatesController(Updates) {

        var vm = this;

        vm.updates = [];

        loadAll();

        function loadAll() {
            Updates.query(function(result) {
                vm.updates = result;
                vm.searchQuery = null;
            });
        }

    }
})();
