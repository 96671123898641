(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['serviceStatistics', 'serviceWiseBriefDashboard'];

    function DashboardController (serviceStatistics, serviceWiseBriefDashboard) {
        var vm = this;
        vm.serviceStatistics = serviceStatistics;
        vm.serviceWiseBriefDashboard=serviceWiseBriefDashboard;

    }
})();
