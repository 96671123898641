(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('contact-detail', {
            parent: 'app',
            url: '/contact-detail',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/contact-detail/contact-detail.html',
                    controller: 'ContactDetailController',
                    controllerAs: 'vm'
                }
            }
        })

        .state('contact-detail.delete', {
                    parent: 'contact-detail',
                    url: '/{id}/contact-detail-delete',

                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/contact-detail/contact-detail-delete.html',
                            controller: 'ContactDetailDeleteController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                entity: ['ContactUs', function(ContactUs) {
                                    return ContactUs.get({id : $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function() {
                            $state.go('contact-detail', null, { reload: 'contact-detail' });
                        }, function() {
                            $state.go('^');
                        });
                    }]
                });
    }
})();
