(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ContactDetailController', ContactDetailController);

    ContactDetailController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'ContactUs'];

    function ContactDetailController ($scope, Principal, LoginService, $state, ContactUs) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.confirmDelete=confirmDelete;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }


         loadAll();

                function loadAll() {
                    ContactUs.query(function(result) {
                        vm.contactuses = result;
//                        console.log(vm.contactuses);
                        vm.searchQuery = null;
                    });
                }
     function confirmDelete (id) {
                ContactUs.delete({id: id});
            }
    }
})();
