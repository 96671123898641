(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('acceptServiceDialog', acceptServiceDialog);

    acceptServiceDialog.$inject = ['$scope', '$stateParams', 'Principal', 'LoginService', '$state', '$uibModalInstance', 'citizenServiceLog', 'CitizenServiceLog', 'citizenServiceDetail', 'CitizenServiceDetails', 'citizenServiceAttachment', 'CitizenServiceLogAttachment', 'FileManagement','CheckContent'];
    function acceptServiceDialog ($scope, $stateParams, Principal, LoginService, $state, $uibModalInstance, citizenServiceLog, CitizenServiceLog, citizenServiceDetail, CitizenServiceDetails, citizenServiceAttachment, CitizenServiceLogAttachment, FileManagement, CheckContent) {
       var vm = this;
       vm.account = null;
       vm.isAuthenticated = null;
       vm.serviceAttachemnt = {};
       vm.login = LoginService.open;
       vm.register = register;
       vm.clear = clear;
       vm.citizenServiceLog = citizenServiceLog;
       vm.assignService = assignService;
       vm.citizenServiceDetail = citizenServiceDetail[0];
       vm.citizenServiceAttachment = citizenServiceAttachment;
       var citizenServiceLogid = null;
       var citizenServiceAttachmentObject;

       function clear () {
           $uibModalInstance.dismiss('cancel');

       }
       $scope.$on('authenticationSuccess', function() {
           getAccount();
       });

       getAccount();

       function getAccount() {
           Principal.identity().then(function(account) {
               vm.account = account;
               vm.isAuthenticated = Principal.isAuthenticated;
           });
       }

       function register () {
           $state.go('register');
       }

        function assignService(){
            if(vm.serviceAttachemnt.file!=null){
                if(CheckContent.checkFile(vm.serviceAttachemnt.file.name)){
                    assignServiceMethod();
                }else alert('Invalid File');
            }else assignServiceMethod();
        }

       function assignServiceMethod(){
           vm.citizenServiceLog.citizenId = $stateParams.citizenId;
           vm.citizenServiceLog.serviceId = $stateParams.serviceId;
           vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
           vm.citizenServiceLog.actionTaken = "Assigned";

           CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
       }

       function onSaveServiceLogSuccess(result)
       {
           $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
           citizenServiceLogid=result.id;
           console.log(vm.citizenServiceDetail);
           vm.citizenServiceDetail.lastComment="Assigned";
           vm.citizenServiceDetail.isAssigned = true;
           CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);
       }

       function onUpdateCitizenServiceDetailSuccess(result)
       {
           $scope.$emit('eServicesApp:CitizenServiceLogUpdate', result);
           if(angular.isDefined(vm.serviceAttachemnt.file))
           {
               saveServiceAttachment();
           }
           else
           {
              $uibModalInstance.close(result);
               vm.isSaving = false;
           }
       }

       function onUpdateCitizenServiceDetailError()
       {
           vm.isSaving = false;
       }

       function onSaveServiceLogError()
       {
           vm.isSaving = false;
       }

       function saveServiceAttachment()
       {
           var file=vm.serviceAttachemnt.file;
           vm.citizenServiceAttachment.serviceLogId=citizenServiceLogid;
           vm.citizenServiceAttachment.attachmentFile=file.name;
           vm.citizenServiceAttachment.attachmentTitle="Assigned";
           vm.citizenServiceAttachment.fileExtension =file;
           CitizenServiceLogAttachment.update(vm.citizenServiceAttachment,onSaveServiceAttachmentSuccess,onSaveServiceAttachmentError);
       }

       function onSaveServiceAttachmentSuccess(result)
       {
           $scope.$emit('eServicesApp:serviceAttachemntUpdate', result);
           var filename = result.id;
           var file =vm.serviceAttachemnt.file;
           FileManagement.saveFile(file,filename);
           vm.isSaving = false;
           citizenServiceAttachmentObject=result;
          var fileNameArray = vm.serviceAttachemnt.file.name.split(".");
          var fileextension="";
          if((fileNameArray.length>1))
          {
              fileextension=fileNameArray[(fileNameArray.length-1)];
          }
          var inputFileName=result.id;
          if(fileextension!=="")
          {
              inputFileName=inputFileName+"."+fileextension;
          }
          citizenServiceAttachmentObject.attachmentFile=inputFileName;
          citizenServiceAttachmentObject.attachmentExtension = fileextension;
          CitizenServiceLogAttachment.update(citizenServiceAttachmentObject, onUpdateServiceAttachmentSuccess, onUpdateServiceAttachmentError);

       }

       function onSaveServiceAttachmentError()
       {
           vm.isSaving = false;
       }

       function onUpdateServiceAttachmentSuccess()
       {
           vm.isSaving = false;
           clear();
       }

       function onUpdateServiceAttachmentError()
       {
           vm.isSaving = false;
       }
    }
})();
