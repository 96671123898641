(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('uploadServiceAttachment',uploadServiceAttachment);

    uploadServiceAttachment.$inject = ['$uibModalInstance', '$stateParams', '$scope', 'entity', 'ServiceFormAttachment', 'FileManagement', 'downloadService'];

    function uploadServiceAttachment($uibModalInstance, $stateParams, $scope, entity, ServiceFormAttachment, FileManagement, downloadService) {
        var vm = this;

        vm.serviceFormAttachment = entity;
        vm.clear = clear;
        vm.save = save;
        var serviceFormAttachemntObject = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.serviceFormAttachment.citizenId = $stateParams.citizenId;
            vm.serviceFormAttachment.serviceId = $stateParams.serviceId;
            alert($stateParams.citizenId);
            ServiceFormAttachment.update(vm.serviceFormAttachment, onSaveSuccess, onSaveError);

       }

       function onSaveSuccess (result) {
           $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
           $uibModalInstance.close(result);
           var filename = result.id;
           var file =vm.serviceFormAttachemnt.file;
           FileManagement.saveFile(file,filename);
           serviceFormAttachemntObject=result;
           var fileNameArray = vm.serviceFormAttachemnt.file.name.split(".");
           var fileextension="";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName=result.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           serviceFormAttachemntObject.attachmentFile=inputFileName;
           serviceFormAttachemntObject.fileExtension = fileextension;
           ServiceFormAttachment.update(serviceFormAttachemntObject, onUpdateSuccess, onUpdateError);
           vm.isSaving = false;
       }

       function onSaveError () {
           vm.isSaving = false;
       }

       function onUpdateSuccess (result) {
          $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
          $uibModalInstance.close(result);
          vm.isSaving = false;
      }

      function onUpdateError () {
          vm.isSaving = false;
      }



    }
})();
