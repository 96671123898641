(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('esignController', esignController);

    esignController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'eSign', 'otpservice'];

    function esignController ($scope, Principal, LoginService, $state, eSign, otpservice) {
        var vm = this;
        vm.showOtpField = false;
        vm.showSuccessMsg = false;
        vm.showFailureMsg = false;
       // vm.name = "";
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.responseMsg = {};
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                $scope.auth = account.authorities;

            });
        }

         vm.getotp=function(){
               eSign.query({aadhaar:vm.aadhaar},function (result){
                vm.responseMsg = result[0];
                if(vm.responseMsg=="auth failed, Error Code: AUA029"){
                    vm.showOtpField = false;
                    vm.showFaluireMsg = true;
                }
                else{
                      vm.showOtpField = true;
                      vm.showSuccessMsg = true;

               }

         });
        }

        vm.setotp=function(){
                       vm.x = {otp:vm.otp};
                       otpservice.save(vm.otp,function (result){
                       vm.showResponseField = true;
                 });
                }
        function register () {
            $state.go('register');
        }
    }
})();
