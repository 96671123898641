(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-service-log-attachment', {
            parent: 'entity',
            url: '/citizen-service-log-attachment',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceLogAttachments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachments.html',
                    controller: 'CitizenServiceLogAttachmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizen-service-log-attachment-detail', {
            parent: 'citizen-service-log-attachment',
            url: '/citizen-service-log-attachment/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceLogAttachment'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachment-detail.html',
                    controller: 'CitizenServiceLogAttachmentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CitizenServiceLogAttachment', function($stateParams, CitizenServiceLogAttachment) {
                    return CitizenServiceLogAttachment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizen-service-log-attachment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizen-service-log-attachment-detail.edit', {
            parent: 'citizen-service-log-attachment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachment-dialog.html',
                    controller: 'CitizenServiceLogAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceLogAttachment', function(CitizenServiceLogAttachment) {
                            return CitizenServiceLogAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-log-attachment.new', {
            parent: 'citizen-service-log-attachment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachment-dialog.html',
                    controller: 'CitizenServiceLogAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                serviceLogId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                attachmentExtension: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log-attachment', null, { reload: 'citizen-service-log-attachment' });
                }, function() {
                    $state.go('citizen-service-log-attachment');
                });
            }]
        })
        .state('citizen-service-log-attachment.edit', {
            parent: 'citizen-service-log-attachment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachment-dialog.html',
                    controller: 'CitizenServiceLogAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceLogAttachment', function(CitizenServiceLogAttachment) {
                            return CitizenServiceLogAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log-attachment', null, { reload: 'citizen-service-log-attachment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-log-attachment.delete', {
            parent: 'citizen-service-log-attachment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-log-attachment/citizen-service-log-attachment-delete-dialog.html',
                    controller: 'CitizenServiceLogAttachmentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CitizenServiceLogAttachment', function(CitizenServiceLogAttachment) {
                            return CitizenServiceLogAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-log-attachment', null, { reload: 'citizen-service-log-attachment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
