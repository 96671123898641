(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance','$scope', 'entity', 'User'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, $scope, entity, User) {
        var vm = this;
        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN','ROLE_HOD', 'ROLE_NODAL_OFFICER', 'ROLE_FIELD_OFFICER', 'ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED', 'ROLE_NODAL_LICENSE'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }
        function onSaveError () {
            vm.isSaving = false;
        }
        function save () {
        vm.authorities
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.langKey = 'en';
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
