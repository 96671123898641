(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('InstallationTestedByAllCESE', InstallationTestedByAllCESE);

    InstallationTestedByAllCESE.$inject = ['$resource'];

    function InstallationTestedByAllCESE ($resource) {
        var resourceUrl =  'api/installation-tested-by-cese-report/:uploadedreportmonthyear/:login';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();

