(function () {
    'use strict';

    angular
        .module('eServicesApp')
        .factory('RegisterHepcUser', RegisterHepcUser);

    RegisterHepcUser.$inject = ['$resource'];

    function RegisterHepcUser ($resource) {
        return $resource('api/registerHepcUser', {}, {});
    }
})();
