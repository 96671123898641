(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('citizenServiceFeeDetailController', citizenServiceFeeDetailController);

    citizenServiceFeeDetailController.$inject = ['$timeout', '$scope', 'Principal','$stateParams','Citizenservicefee', 'CitizenservicefeebyApplicationNo'];

    function citizenServiceFeeDetailController ($timeout, $scope, Principal, $stateParams, Citizenservicefee, CitizenservicefeebyApplicationNo) {
        var vm = this;

              vm.account = null;
              vm.isAuthenticated = null;
       //       vm.login = LoginService.open;
              vm.register = register;

              $scope.$on('authenticationSuccess', function() {
                  getAccount();
              });

              getAccount();

              function getAccount() {
                  Principal.identity().then(function(account) {
                      vm.account = account;
                      vm.isAuthenticated = Principal.isAuthenticated;
                  });
              }
              function register () {
                  $state.go('register');
              }

              loadCitizenServiceFee();

              function loadCitizenServiceFee(){
                  vm.citizenServiceFees = CitizenservicefeebyApplicationNo.query({applicationNo:$stateParams.applicationNo,citizenId:$stateParams.citizenId});
              }

           }
       })();
