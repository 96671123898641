(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('fillServiceFormHEPCController', fillServiceFormHEPCController);

     fillServiceFormHEPCController.$inject = ['$window','$sce','$timeout', 'Principal', '$scope', '$stateParams', 'DepartmentSrv', 'citizenServiceLog', 'serviceFormAttachment', 'ServiceFormAttachment', 'CitizenServiceDetailByCitizenService', 'CitizenServiceDetails', 'citizenServiceFormFieldData', 'ServiceFormFieldWithData', 'citizenServiceAttachment', 'CitizenServiceLog', 'CitizenServiceFormFieldData', 'FileManagement', 'ServiceFormAttachmentByApplicationNo', 'downloadService', 'Citizen', 'FeedbackFields','Auth', 'HepcUpdateStatus'];

    function fillServiceFormHEPCController( $window, $sce,$timeout, Principal, $scope, $stateParams, DepartmentSrv, citizenServiceLog, serviceFormAttachment, ServiceFormAttachment, CitizenServiceDetailByCitizenService, CitizenServiceDetails, citizenServiceFormFieldData, ServiceFormFieldWithData, citizenServiceAttachment, CitizenServiceLog, CitizenServiceFormFieldData, FileManagement, ServiceFormAttachmentByApplicationNo, downloadService, Citizen, FeedbackFields, Auth, HepcUpdateStatus) {

        var vm = this;
        vm.account = null;
//        vm.isAuthenticated = null;
        vm.serviceFormFields = [];
        $scope.$sce = $sce;
        vm.serviceFormAttachment = serviceFormAttachment;
        vm.uploadAttachment = uploadAttachment;
        vm.saveFormFieldData = saveFormFieldData;
        vm.citizenServiceLog = citizenServiceLog;
        vm.citizenServiceFormFieldData = citizenServiceFormFieldData;
        vm.citizenServiceAttachment = citizenServiceAttachment;
        var projectServiceLogid=null;
        var formfielddataObjectArrayLength = 0;
        var objectCount = 0;
        var saveLogFlag = false;
        var formFieldDataValues="";
        var actionOnForm = "Form Filled";
        var serviceFormAttachemntObject = null;
        vm.downloadFile=downloadFile;
        vm.deleteAttachment = deleteAttachment;
        vm.redirectToInvestHaryana = redirectToInvestHaryana;
        vm.citizenServiceDetail = null;
        vm.feedback = {};
        vm.updateHepcStatus = {};

        vm.registerAccount = {};
//        alert($stateParams.transactionId);
        var uname = $stateParams.uname;
        var investorname = $stateParams.investorname;
        var address = $stateParams.address;
        var useremail = $stateParams.useremail;
        var country = $stateParams.country;
        var state = $stateParams.state;
        var city = $stateParams.city;
        var mobile = $stateParams.mobile;
        var project_site_district = $stateParams.project_site_district;
        var projectid = $stateParams.projectid;
        var serviceid = $stateParams.serviceid;
        var projectserviceid = $stateParams.projectserviceid;
        vm.applicationNo = $stateParams.applicationNo;

        vm.rememberMe = false;

        Auth.login({
             username: uname+"_hepc",
             password: new AesUtil().encryptPassword(uname+"_hepc"),
             // password: uname+"_hepc",
             rememberMe: vm.rememberMe
         }).then(function () {
            vm.authenticationError = false;
            loadServiceFormDetails();

        }).catch(function () {
        getAccount();
            vm.authenticationError = true;
            loadServiceFormDetails();
        });

       function getAccount() {
            Principal.identity().then(function(account) {

                vm.account = account;

                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.account);
                if($scope.auth=="ROLE_HEPC_USER"){
    //                    alert($scope.auth);
                   vm.hidenavbar = false;
                }

            });
        }

        console.log(vm.account);

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.submitForm = function(){
            $log.debug($scope.entity);
        }

        vm.departmentSrv = DepartmentSrv.get({id : $stateParams.ceiserviceId});

        vm.citizenServiceDetail = CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.ceiserviceId,pinCode:$stateParams.applicationNo});
        console.log(vm.citizenServiceDetail);

        function loadServiceFormDetails() {
            vm.serviceFormFields = ServiceFormFieldWithData.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.ceiserviceId,applicationNo:$stateParams.applicationNo});

        }
        function uploadAttachment () {
            vm.isSaving = true;
            vm.serviceFormAttachment.citizenId = $stateParams.citizenId;
            vm.serviceFormAttachment.serviceId = $stateParams.ceiserviceId;
            vm.serviceFormAttachment.applicationNo = $stateParams.applicationNo;
            ServiceFormAttachment.update(vm.serviceFormAttachment, onUploadAttachmentSuccess, onUploadAttachmentError);
       }
       function onUploadAttachmentSuccess (result) {
           $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
//           $uibModalInstance.close(result);

           var filename = result.id;
           var file =vm.serviceFormAttachemnt.file;
           FileManagement.saveFile(file,filename);
           serviceFormAttachemntObject=result;
           var fileNameArray = vm.serviceFormAttachemnt.file.name.split(".");
           var fileextension="";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName=result.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           serviceFormAttachemntObject.attachmentFile=inputFileName;
           serviceFormAttachemntObject.fileExtension = fileextension;
           ServiceFormAttachment.update(serviceFormAttachemntObject, onServiceFormAttachemntSuccess, onServiceFormAttachemntError);
           loadServiceAttachments();
           vm.isSaving = false;
       }

       function onUploadAttachmentError () {
           vm.isSaving = false;
       }

       function onServiceFormAttachemntSuccess (result) {
          $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
//          $uibModalInstance.close(result);
          loadServiceAttachments();
          vm.isSaving = false;

      }

    function onServiceFormAttachemntError () {
       vm.isSaving = false;
    }

    var vmlength = null;

    function saveFormFieldData()
    {

        vmlength = vm.serviceFormFields.length;
        for(var i= 0; i<vmlength;i++)
        {
            var data = vm.serviceFormFields[i];
            formFieldDataValues=formFieldDataValues + vm.serviceFormFields[i].formFieldData+" | ";
            vm.isSaving = true;
             if (data.id !== null) {
                actionOnForm = "Edited Form";
                CitizenServiceFormFieldData.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
            } else {
                CitizenServiceFormFieldData.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
            }
            objectCount = i;
        }
    }
        function onSaveServiceFormFieldDataSuccess(result)
        {
            if(!saveLogFlag)
            {
                if(objectCount ===(vmlength-1))

                var citizenServiceDetailObject = vm.citizenServiceDetail[0];
                citizenServiceDetailObject.lastComment=actionOnForm;
                citizenServiceDetailObject.isFormFilled=true;
                if(citizenServiceDetailObject.status =="NA")
                {
                    citizenServiceDetailObject.status =="New"
                }
                CitizenServiceDetails.update(citizenServiceDetailObject,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);

                saveServiceLog();
//                updateHepcStatusLog();
                alert(actionOnForm +" Successfully!");
                console.log(result);
                if(result.required == true){
                    vm.feedback.applicationNo = result.applicationNo;
                    vm.feedback.serviceId = result.serviceId;
                    vm.feedback.citizenId = result.citizenId;
                    FeedbackFields.get({applicationno: vm.feedback.applicationNo, citizenid: vm.feedback.citizenId, serviceid: vm.feedback.serviceId},onFeedbackSuccess,onFeedbackError);
                }
                saveLogFlag = true;
            }
        }


        function onFeedbackSuccess(){


            console.log("feedback success");
        }
        function onFeedbackError(){

            console.log("feedback error");
        }

        function onSaveServiceFormFieldDataError()
        {
        }

        function saveServiceLog(){

            vm.citizenServiceLog.actionTaken = actionOnForm;
            vm.citizenServiceLog.comment = formFieldDataValues;
            vm.citizenServiceLog.citizenId = $stateParams.citizenId;
            vm.citizenServiceLog.serviceId = $stateParams.ceiserviceId;
            vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
        }

        function onSaveServiceLogSuccess(result)
        {
            vm.isSaving = false;

        }

        function onUpdateCitizenServiceDetailSuccess(result)
        {
//            "actionTaken": "ServiceFormEdited",
//            "commentByUserLogin": "", // Name of Person taken Action
//            "commentDate": "2017-08-29T09:43:42.335Z", // application received date
//            "comments": "ServiceFormEdited", //
//            "id": "", // Leave it blank
//            "projectid": "projectid", // projectid of user
//            "serviceid": "serviceid" // serviceid of user


        }
        function onHepcUpdateStatusSuccess(result)
        {

            vm.isSaving = false;
        }

        function onHepcUpdateStatusError()
        {

            vm.isSaving = false;
        }

        function onUpdateCitizenServiceDetailError()
        {
            vm.isSaving = false;
        }



        function onSaveServiceLogError(result)
        {

        }

        vm.serviceFormAttachments = [];

        loadServiceAttachments();

        function loadServiceAttachments() {
                vm.serviceFormAttachments = ServiceFormAttachmentByApplicationNo.query({applicationNo:$stateParams.applicationNo,citizenId:$stateParams.citizenId,serviceId:$stateParams.ceiserviceId});

        }

        function downloadFile(filename, downloadFileName)
        {
            downloadService.download(filename, downloadFileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function deleteAttachment (id) {
            ServiceFormAttachment.delete({id: id},
                function () {
                    loadServiceAttachments();
                });
        }

        function updateHepcStatusLog(){
            vm.updateHepcStatus.id="";
            vm.updateHepcStatus.actionTaken="ServiceFormEdited";
            vm.updateHepcStatus.comments="ServiceFormEdited";
            vm.updateHepcStatus.projectid=projectid;
            vm.updateHepcStatus.serviceid=serviceid;
            vm.updateHepcStatus.commentByUserLogin=uname;
//            alert("HEPC Response..");
            HepcUpdateStatus.save(vm.updateHepcStatus, onHepcUpdateStatusSuccess, onHepcUpdateStatusError);

        }

        function  redirectToInvestHaryana() {
            var landingUrl = "https://investharyana.in/#/projectdetailjist/"+projectid;
              alert(landingUrl);
              $window.location.href = landingUrl;
        }
        $scope.openNewLink = function() {
            $window.open('https://egrashry.nic.in/', '_blank');
        };
    }
})();
