(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DistrictDialogController', DistrictDialogController);

    DistrictDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'State', 'District'];

    function DistrictDialogController ($timeout, $scope, $state, $stateParams, entity, State, District) {
        var vm = this;

        vm.district = entity;
        vm.clear = clear;
        vm.save = save;

        loadAll();

        function loadAll() {
            State.query(function(result) {
                vm.states = result;
                vm.searchQuery = null;
                //console.log(vm.states);
            });
        }

          load();
          function load () {
                       vm.id = vm.district.stateId;
                       State.get({id: vm.id},function(result){
                             vm.districtvalue=result;
                             $scope.selectedState = {id: result.id, stateName: result.stateName}
                                        });
                        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
             $state.go('district', null, { reload: 'district' });
        }

        function save () {
            vm.isSaving = true;

            //alert($scope.selectedState.id);

            vm.district.stateId = $scope.selectedState.id;
            if (vm.district.id !== null) {
                District.update(vm.district, onSaveSuccess, onSaveError);
            } else {
                District.save(vm.district, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:districtUpdate', result);
             $state.go('district', null, { reload: 'district' });
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
