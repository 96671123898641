(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('clearanceGivenDetail', {
            parent: 'app',
            url: '/clearanceGivenDetail?:citizenId:serviceId:applicationNo:serviceName',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/clearanceGivenDetail/clearanceGivenDetail.html',
                    controller: 'ClearanceGivenDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                     return {
                            citizenId: null,
                            serviceId: null,
                            isApplied: null,
                            appliedOnDate: null,
                            appliedBy: null,
                            isAssigned: null,
                            assignedBy: null,
                            assignedOnDate: null,
                            isFormFilled: null,
                            formFilledBy: null,
                            formFilledOnDate: null,
                            isRequired: null,
                            isPaymentMade: null,
                            paymentMadeOnDate: null,
                            paymentMadeBy: null,
                            isRejected: null,
                            rejectedBy: null,
                            rejectedOnDate: null,
                            isClearanceGiven: null,
                            clearanceGivenBy: null,
                            clearanceGivenOnDate: null,
                            isDeactivated: null,
                            lastComment: null,
                            pinCode: null,
                            requiredBy: null,
                            requiredOnDate: null,
                            deactivatedBy: null,
                            deactivatedOnDate: null,
                            isNotRequired: null,
                            notRequiredBy: null,
                            notRequiredOnDate: null,
                            departmentId: null,
                            status: null,
                            lastCommentedDate: null,
                            id: null
                        };
                },
                user: function () {
                    return {
                        id: null, login: null, firstName: null, lastName: null, email: null,
                        activated: true, langKey: null, createdBy: null, createdDate: null,
                        lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                        resetKey: null, authorities: null
                    };
                },
                citizenServiceLog: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        comment: null,
                        applicationNo: null,
                        id: null

                    };
                }

            }
        })
        .state('applicationFormView', {
                    parent: '',
                    url: '/applicationFormView?:citizenId:serviceId:applicationNo:serviceName',
                       data: {
                           authorities: ['ROLE_HOD','ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
                       },
                        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                           $uibModal.open({
                               templateUrl: 'app/service-clearance-action/serviceFormFilled.html',
                               controller: 'serviceFormFilledController',
                               controllerAs: 'vm',
                               backdrop: 'static',
                               size: 'lg',
                               resolve: {
                                    departmentSrv: ['DepartmentSrv', function(DepartmentSrv) {
                                        return DepartmentSrv.get({id : $stateParams.serviceId}).$promise;
                                    }]

                                }
                           }).result.then(function() {
                               $state.go('clearanceGivenDetail', null, { reload: 'clearanceGivenDetail' });
                           }, function() {
                               $state.go('clearanceGivenDetail',{
                               serviceId: $stateParams.serviceId,
                               serviceName: $stateParams.serviceName
                               });
                           });
                        }]

                })
    }



})();
