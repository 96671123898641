(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizenservicefee', {
            parent: 'entity',
            url: '/citizenservicefee',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Citizenservicefees'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefees.html',
                    controller: 'CitizenservicefeeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizenservicefee-detail', {
            parent: 'citizenservicefee',
            url: '/citizenservicefee/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Citizenservicefee'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefee-detail.html',
                    controller: 'CitizenservicefeeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Citizenservicefee', function($stateParams, Citizenservicefee) {
                    return Citizenservicefee.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizenservicefee',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizenservicefee-detail.edit', {
            parent: 'citizenservicefee-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefee-dialog.html',
                    controller: 'CitizenservicefeeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Citizenservicefee', function(Citizenservicefee) {
                            return Citizenservicefee.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizenservicefee.new', {
            parent: 'citizenservicefee',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefee-dialog.html',
                    controller: 'CitizenservicefeeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                feeFor: null,
                                feeAmount: null,
                                isPaid: null,
                                transactionId: null,
                                feeSuggestedBy: null,
                                feeSugestedOnDate: null,
                                feePaidBy: null,
                                feePaidOnDate: null,
                                serviceFeeResponse: null,
                                comment: null,
                                departmentId: null,
                                paymentMode: null,
                                offLinePaymentGuideline: null,
                                applicationNo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizenservicefee', null, { reload: 'citizenservicefee' });
                }, function() {
                    $state.go('citizenservicefee');
                });
            }]
        })
        .state('citizenservicefee.edit', {
            parent: 'citizenservicefee',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefee-dialog.html',
                    controller: 'CitizenservicefeeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Citizenservicefee', function(Citizenservicefee) {
                            return Citizenservicefee.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizenservicefee', null, { reload: 'citizenservicefee' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizenservicefee.delete', {
            parent: 'citizenservicefee',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizenservicefee/citizenservicefee-delete-dialog.html',
                    controller: 'CitizenservicefeeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Citizenservicefee', function(Citizenservicefee) {
                            return Citizenservicefee.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizenservicefee', null, { reload: 'citizenservicefee' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
