(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ceseInstallationTestedReportController', ceseInstallationTestedReportController);

    ceseInstallationTestedReportController.$inject = ['InstallationTestedByCESE','$scope'];

    function ceseInstallationTestedReportController(InstallationTestedByCESE, $scope) {

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        var vm = this;
        vm.selectedValue = "10";
        vm.installationTestedByCESE = [];

        loadAll();

        function loadAll() {
            InstallationTestedByCESE.query(function(result) {
                vm.installationTestedByCESE = result;
                console.log(result);
                vm.searchQuery = null;
            });
        }

        $scope.setPageSize = function (pageSizeVal) {

            $scope.pageSize = pageSizeVal;

        }

        $scope.pageChangeHandler = function(num) {

        };
    }
})();
