(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('installation-tested-by-cese', {
            parent: 'entity',
            url: '/installation-tested-by-cese',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'InstallationTestedByCESE'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese.html',
                    controller: 'InstallationTestedByCESEController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('installation-tested-by-cese-detail', {
            parent: 'installation-tested-by-cese',
            url: '/installation-tested-by-cese/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'InstallationTestedByCESE'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese-detail.html',
                    controller: 'InstallationTestedByCESEDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InstallationTestedByCESE', function($stateParams, InstallationTestedByCESE) {
                    return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'installation-tested-by-cese',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('installation-tested-by-cese-detail.edit', {
            parent: 'installation-tested-by-cese-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese-dialog.html',
                    controller: 'InstallationTestedByCESEDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('installation-tested-by-cese.new', {
            parent: 'installation-tested-by-cese',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese-dialog.html',
                    controller: 'InstallationTestedByCESEDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firmName: null,
                                firmEmail: null,
                                firmMobileNumber: null,
                                firmAddress: null,
                                voltageLevel: null,
                                installationCapacityDetail: null,
                                approvedSldDetail: null,
                                approvedSldDetailMemoNo: null,
                                approvedSldDate: null,
                                inspectionConductedHour: null,
                                inspectionConductedMinute: null,
                                inspectionConductedMeridian: null,
                                inspectionType: null,
                                isOwnerUndertakingTaken: null,
                                isCoveredUnderSec54OfIEAct: null,
                                remarks: null,
                                actionTakenBy: null,
                                inspectionConductedOnDate: null,
                                installationId: null,
                                actionTakenOnDate: null,
                                uploadedReportMonthYear: null,
                                reportUploadingOnDate: null,
                                reportUploadingMonth: null,
                                reportUploadingYear: null,
                                ownerOfPremises: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('installation-tested-by-cese', null, { reload: 'installation-tested-by-cese' });
                }, function() {
                    $state.go('installation-tested-by-cese');
                });
            }]
        })
        .state('installation-tested-by-cese.edit', {
            parent: 'installation-tested-by-cese',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese-dialog.html',
                    controller: 'InstallationTestedByCESEDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('installation-tested-by-cese', null, { reload: 'installation-tested-by-cese' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('installation-tested-by-cese.delete', {
            parent: 'installation-tested-by-cese',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/installation-tested-by-cese/installation-tested-by-cese-delete-dialog.html',
                    controller: 'InstallationTestedByCESEDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('installation-tested-by-cese', null, { reload: 'installation-tested-by-cese' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
