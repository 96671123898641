(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('commentDialogController', commentDialogController);

    commentDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance'];
    function commentDialogController ($timeout, $scope, $stateParams, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
