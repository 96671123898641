(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);


      stateConfig.$inject = ['$stateProvider'];

        function stateConfig($stateProvider) {
            $stateProvider.state('self-certification-through-own-engineer-detail', {
                parent: 'app',
                url: '/self-certification-through-own-engineer-detail',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_HOD']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/self-certification-through-own-engineer-detail/self-certification-through-own-engineer-detail.html',
                        controller: 'SelfCertificationThroughOwnEngineerDetailController',
                        controllerAs: 'vm'
                    }
                }
            });
        }
    })();


