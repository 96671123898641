(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Citizen', 'DepartmentUserProfile','UpdatesActive'];

    function HomeController ($scope, Principal, LoginService, $state, Citizen, DepartmentUserProfile, UpdatesActive) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.hidenavbar = true;
        vm.updates = [];
        getAccount();

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });




         $('#myModal').modal('show');


        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(vm.account != null) {
                    loadAll();
                    $scope.auth = account.authorities;

                    if ($scope.auth == "ROLE_USER" || $scope.auth == "ROLE_LICENSE_USER") {
                        Citizen.get({id: vm.account.id}, function (result) {
                            vm.stateValue = result;
                            $scope.stateId = result.stateId;
                            $scope.districtId = result.districtId;
                            $scope.role = account.authorities;
                            if ($scope.stateId == "" || $scope.stateId == null || $scope.districtId == "" || $scope.districtId == null) {
                                $state.go('citizen-profile');
                            }
                        })
                    }

                    if ($scope.auth == "ROLE_NODAL_OFFICER" || $scope.auth == "ROLE_ACCOUT_OFFICER" || $scope.auth == "ROLE_FIELD_OFFICER") {
                        DepartmentUserProfile.get({id: vm.account.id}, function (result) {
                            vm.stateValue = result;
                            $scope.departmentId = result.departmentId;
                            $scope.stateId = result.state;
                            $scope.districtId = result.districtId;
                            $scope.role = account.authorities;
                            if ($scope.departmentId == "" || $scope.departmentId == null || $scope.stateId == "" || $scope.stateId == null || $scope.districtId == "" || $scope.districtId == null) {
                                $state.go('department-profile');
                            }
                        })
                    }
                }
            });
        }


        function loadAll() {
            UpdatesActive.queryActive(function(result) {
                vm.updates = result;
                vm.searchQuery = null;
            });
        }

        function register () {
            $state.go('register');
        }
    }
})();
