(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('PaymentResponseController', PaymentResponseController);

    PaymentResponseController.$inject = ['Principal', '$scope', '$stateParams'];

    function PaymentResponseController ( Principal, $scope, $stateParams) {
        var vm = this;

        alert($stateParams.transactionId);
        vm.transactionid = $stateParams.transactionId;
        vm.transactiondate= $stateParams.transactionDate;
        vm.status =$stateParams.status;
        vm.amount =$stateParams.amount;


    }
})();
