(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('Clearance_user_infoDetailController', Clearance_user_infoDetailController);

    Clearance_user_infoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Clearance_user_info'];

    function Clearance_user_infoDetailController($scope, $rootScope, $stateParams, previousState, entity, Clearance_user_info) {
        var vm = this;

        vm.clearance_user_info = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:clearance_user_infoUpdate', function(event, result) {
            vm.clearance_user_info = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
