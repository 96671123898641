(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('AccessTokenController', AccessTokenController);

    AccessTokenController.$inject = ['AccessToken'];

    function AccessTokenController(AccessToken) {

        var vm = this;

        vm.accessTokens = [];

        loadAll();

        function loadAll() {
            AccessToken.query(function(result) {
                vm.accessTokens = result;
                vm.searchQuery = null;
            });
        }
    }
})();
