(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('CitizenServiceCompleteDetails', CitizenServiceCompleteDetails);

    CitizenServiceCompleteDetails.$inject = ['$resource'];

    function CitizenServiceCompleteDetails ($resource) {
        var resourceUrl =  'api/citizen-service-complete-details/citizen/:citizenId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();

