(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('serviceFormController', serviceFormController);

    serviceFormController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectServiceFormFieldData','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLog','FileManagement','Projectservicedetail','Projectserviceformfielddata','serviceFormFieldDataCollection'];
    function serviceFormController ($timeout, $scope, $stateParams, $uibModalInstance,projectServiceFormFieldData, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLog, FileManagement, Projectservicedetail, Projectserviceformfielddata,serviceFormFieldDataCollection) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServiceFormFieldData=projectServiceFormFieldData;
        vm.projectService = $stateParams.projectService;
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;

        vm.clear = clear;
        vm.saveFormFieldData = saveFormFieldData;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadServiceFormFields();
        function loadServiceFormFields() {
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
        }
        var combineFilledForm="";
        function saveFormFieldData()
        {
            var vmlength = vm.serviceFormFieldData.length;
            for(var i= 0; i<vmlength;i++)
            {
                var data = vm.serviceFormFieldData[i];
                combineFilledForm=combineFilledForm + vm.serviceFormFieldData[i].formfieldvalue+" | ";
                vm.isSaving = true;
                data.projectid = vm.projectService.projectid;
                if (data.id !== null) {
                    Projectserviceformfielddata.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                } else {
                    Projectserviceformfielddata.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                }
                if(i === (vmlength-1))
                {
                    saveProjectServiceLog();
                }
            }
        }
        function onSaveServiceFormFieldDataSuccess(result)
        {}
        function onSaveServiceFormFieldDataError()
        {}
        function saveProjectServiceLog(result){
            $scope.$emit('eServicesApp:projectserviceformfielddataUpdate', result);
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.comments=vm.projectServiceLog.comments + " | " +combineFilledForm;
            vm.projectServiceLog.actionTaken="ServiceFormEdited";
            ProjectServiceLog.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('eServicesApp:saveProjectServiceLog', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.formFilledStatus=true;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
//            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Service Form Field Data Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
//            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }
    }
})();
