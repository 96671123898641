(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('PreviousTwoYearDataController', PreviousTwoYearDataController);

    PreviousTwoYearDataController.$inject = ['PreviousTwoYearData'];

    function PreviousTwoYearDataController(PreviousTwoYearData) {

        var vm = this;

        vm.previousTwoYearData = [];

        loadAll();

        function loadAll() {
            PreviousTwoYearData.query(function(result) {
                vm.previousTwoYearData = result;
                vm.searchQuery = null;
            });
        }
    }
})();
