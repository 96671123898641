(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('RasController', RasController);

    RasController.$inject = ['Ras'];

    function RasController(Ras) {

        var vm = this;

        vm.ras = [];

        loadAll();

        function loadAll() {
            Ras.query(function(result) {
                vm.ras = result;
                vm.searchQuery = null;
            });
        }
    }
})();
