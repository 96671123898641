(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('AppliedServicesController', AppliedServicesController);

    AppliedServicesController.$inject = ['DepartmentService'];

    function AppliedServicesController(DepartmentService) {

        var vm = this;

        vm.departmentServices = [];

        loadAll();

        function loadAll() {
            DepartmentService.query(function(result) {
                vm.departmentServices = result;
                vm.searchQuery = null;
            });
        }
    }
})();

