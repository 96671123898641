(function() {
    'use strict';

    angular
       .module('eServicesApp')
        .controller('AssignCharteredController', AssignCharteredController);

    AssignCharteredController.$inject = ['$window','$stateParams','$uibModalInstance','$scope', 'Principal', 'citizenServiceLog', 'CitizenServiceLog', 'LoginService', '$state', 'citizenServiceDetail', 'AssignChartered','Citizen', 'CitizenServiceDetails'];

    function AssignCharteredController ( $window, $stateParams,$uibModalInstance, $scope, Principal, citizenServiceLog,  CitizenServiceLog, LoginService, $state,citizenServiceDetail, AssignChartered, Citizen, CitizenServiceDetails) {
        var vm = this;

//        alert("gg");
        vm.save = save;
        vm.SelectChartered ="unselected";
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.citizenServiceLog = citizenServiceLog;
        vm.citizenServiceDetail = citizenServiceDetail[0];
        vm.clear = clear;
        vm.citizen = {};
        vm.showCharteredDetail = true;
        vm.inspectionAuthority = "cei";
        var actionMessage = "";
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.IsVisible = false;
        $scope.inspectionAuthority = function(value)
        {
            vm.inspectionAuthority = value;
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = value == "chartered";
        }

        vm.serviceId = $stateParams.serviceId;

        //alert(vm.serviceId);

        if(vm.serviceId=="8567e3c4-b4f3-472c-b8ee-57692f5456b4"){
        vm.hideChartered = true;
        }
        vm.citizenProfile = AssignChartered.query({authorities:"[ROLE_CHARTERED]"}, function(result){
        vm.authorties = result;
        console.log(vm.authorties);
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
//            $window.history.back();

        }

        vm.charteredDetail = function getCharteredDetail(){
            vm.citizen.id = $scope.selectedChartered.id;
                if(vm.citizen.id!="" || vm.citizen.id!=null){
                vm.showCharteredDetail = false;
                }
                if(vm.citizen.id=="" || vm.citizen.id==null){
                  vm.showCharteredDetail = true;
                }
               vm.citizenDetail = Citizen.get({id:vm.citizen.id}, function(result){

            })

        }

       function save()
        {
            if(vm.inspectionAuthority =="chartered")
            {
                vm.citizenServiceDetail.inspectionThrough = "chartered";
                actionMessage = "Inspection Through";
//                vm.citizenServiceDetail.lastComment="Chartered Assigned";
                vm.citizenServiceDetail.isCharteredAssign = true;
                vm.citizenServiceLog.comment = "Opted inspection through Chartered";
                vm.citizenServiceDetail.chartered_id = $scope.selectedChartered.id;
            }

            if(vm.inspectionAuthority =="cei")
            {
               vm.citizenServiceDetail.inspectionThrough = "department";
               actionMessage = "Inspection Through";
               vm.citizenServiceDetail.isCharteredAssign = false;
               vm.citizenServiceLog.comment = "Opted inspection through Department";
            }

            if(vm.inspectionAuthority =="ownEngineer")
            {
                alert(vm.inspectionAuthority);
               vm.citizenServiceDetail.inspectionThrough = "ownEngineer";
               actionMessage = "Inspection Through";
               vm.citizenServiceDetail.isCharteredAssign = false;
               vm.citizenServiceLog.comment = "Opted inspection through own Engineer";
            }


            vm.citizenServiceDetail.charteredAssignBy = vm.account.login;
            vm.citizenServiceDetail.lastComment=actionMessage;
            CitizenServiceDetails.update(vm.citizenServiceDetail,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);

        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
        function onUpdateCitizenServiceDetailSuccess(result){


//            $scope.$emit('eServicesApp:citizen-dashboard', result);
            vm.citizenServiceLog.citizenId = $stateParams.citizenId;
            vm.citizenServiceLog.serviceId = $stateParams.serviceId;
            vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
            vm.citizenServiceLog.actionTaken = actionMessage;
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);


        }

        function onUpdateCitizenServiceDetailError(result){
        vm.isSaving = false;
        }

        function onSaveServiceLogSuccess(result)
        {
//            $state.go('citizen-dashboard');
//            $route.reload();
            vm.isSaving = false;

            clear();
        }

        function onSaveServiceLogError()
        {
            vm.isSaving = false;
        }
    }
})();
