(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('cese-installation-tested-report', {
            parent: 'app',
            url: '/cese-installation-tested-report',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report.html',
                    controller: 'ceseInstallationTestedReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })

        .state('cese-installation-tested-report-stat', {
            parent: 'app',
            url: '/cese-installation-tested-report-stat',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_HOD','ROLE_ADMIN', 'ROLE_NODAL_OFFICER'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-stat.html',
                    controller: 'ceseInstallationTestedReportStatController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['InstallationTestedByAllStatCESE', function(InstallationTestedByAllStatCESE) {
                    return InstallationTestedByAllStatCESE.query({uploadedreportmonthyear : "JUNE-2018"}).$promise;
                }]
            }
        })

        .state('cese-installation-tested-report-all', {
           parent: 'app',
           url: '/cese-installation-tested-report-all?:uploadedreportmonthyear:login',
           data: {
               authorities: ['ROLE_CHARTERED','ROLE_ADMIN','ROLE_HOD','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
           },
            views: {
                'content@': {
                   templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-all.html',
                   controller: 'ceseInstallationTestedReportAllController',
                   controllerAs: 'vm'
                }
            },
            resolve: {
//                entity: ['InstallationTestedByAllCESE', function(InstallationTestedByAllCESE) {
//                    return InstallationTestedByAllCESE.get({uploadedreportmonthyear : "JUNE-2018", login:$stateParams.login}).$promise;
//                }]

            }
        })


//        .state('cese-installation-tested-report-all', {
//            parent: 'cese-installation-tested-report-stat',
//            url: '/cese-installation-tested-report-all?:uploadedreportmonthyear:login',
//            data: {
//                authorities: ['ROLE_HOD','ROLE_ADMIN']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-all.html',
//                    controller: 'ceseInstallationTestedReportAllController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['InstallationTestedByAllCESE', function(InstallationTestedByAllCESE) {
//                            return InstallationTestedByAllCESE.get({uploadedreportmonthyear : "JUNE-2018", login:$stateParams.login}).$promise;
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('^', {}, { reload: false });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })

        .state('cese-installation-tested-report-detail', {
            parent: 'cese-installation-tested-report',
            url: '/cese-installation-tested-report/{id}',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER'],
                pageTitle: 'InstallationTestedByCESE'
            },
            views: {
                'content@': {
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-detail.html',
                    controller: 'ceseInstallationTestedReportDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InstallationTestedByCESE', function($stateParams, InstallationTestedByCESE) {
                    return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'installation-tested-by-cese',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('cese-installation-tested-report-detail.edit', {
            parent: 'cese-installation-tested-report-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-dialog.html',
                    controller: 'ceseInstallationTestedReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cese-installation-tested-report.new', {
            parent: 'cese-installation-tested-report',
            url: '/new',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-dialog.html',
                    controller: 'ceseInstallationTestedReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firmEmail: null,
                                firmMobileNumber: null,
                                firmAddress: null,
                                voltageLevel: null,
                                installationCapacityDetail: null,
                                approvedSldDetail: null,
                                approvedSldDetailMemoNo: null,
                                approvedSldDate: null,
                                inspectionConductedHour: null,
                                inspectionConductedMinute: null,
                                inspectionConductedMeridian: null,
                                inspectionType: null,
                                isOwnerUndertakingTaken: null,
                                isCoveredUnderSec54OfIEAct: null,
                                remarks: null,
                                actionTakenBy: null,
                                inspectionConductedOnDate: null,
                                installationId: null,
                                actionTakenOnDate: null,
                                uploadedReportMonthYear: null,
                                reportUploadingOnDate: null,
                                reportUploadingMonth: null,
                                reportUploadingYear: null,
                                ownerOfPremises: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('cese-installation-tested-report', null, { reload: 'cese-installation-tested-report' });
                }, function() {
                    $state.go('cese-installation-tested-report');
                });
            }]
        })
        .state('cese-installation-tested-report.edit', {
            parent: 'cese-installation-tested-report',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-dialog.html',
                    controller: 'ceseInstallationTestedReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cese-installation-tested-report', null, { reload: 'cese-installation-tested-report' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cese-installation-tested-report.delete', {
            parent: 'cese-installation-tested-report',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_CHARTERED','ROLE_ADMIN', 'ROLE_NODAL_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/cese-installation-tested-report/cese-installation-tested-report-delete.html',
                    controller: 'ceseInstallationTestedReportDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InstallationTestedByCESE', function(InstallationTestedByCESE) {
                            return InstallationTestedByCESE.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cese-installation-tested-report', null, { reload: 'cese-installation-tested-report' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
