(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('cese-electrical-installation-details', {
            parent: 'entity',
            url: '/cese-electrical-installation-details',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CeseElectricalInstallationDetails'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details.html',
                    controller: 'CeseElectricalInstallationDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('cese-electrical-installation-details-detail', {
            parent: 'cese-electrical-installation-details',
            url: '/cese-electrical-installation-details/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CeseElectricalInstallationDetails'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details-detail.html',
                    controller: 'CeseElectricalInstallationDetailsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CeseElectricalInstallationDetails', function($stateParams, CeseElectricalInstallationDetails) {
                    return CeseElectricalInstallationDetails.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'cese-electrical-installation-details',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('cese-electrical-installation-details-detail.edit', {
            parent: 'cese-electrical-installation-details-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details-dialog.html',
                    controller: 'CeseElectricalInstallationDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CeseElectricalInstallationDetails', function(CeseElectricalInstallationDetails) {
                            return CeseElectricalInstallationDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cese-electrical-installation-details.new', {
            parent: 'cese-electrical-installation-details',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details-dialog.html',
                    controller: 'CeseElectricalInstallationDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                isSiteChecked: null,
                                checkedDate: null,
                                installationOwnerName: null,
                                installtionOwnerState: null,
                                installationOwnerStateId: null,
                                installationOwnerDistrict: null,
                                installationOwnerDistrictId: null,
                                installationOwnerTehsile: null,
                                installationOwnerAddress: null,
                                installationOwnerPin: null,
                                anyObservationNoticed: null,
                                observationConveytoEI: null,
                                attachedReportForm: null,
                                actionTakenBy: null,
                                actionTakenOnDate: null,
                                applicationNo: null,
                                checkedDateHour: null,
                                checkedDateMinute: null,
                                responsiblePersonName: null,
                                responsiblePersonContact: null,
                                attachmentFileTitle: null,
                                attachmentFileExtension: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('cese-electrical-installation-details', null, { reload: 'cese-electrical-installation-details' });
                }, function() {
                    $state.go('cese-electrical-installation-details');
                });
            }]
        })
        .state('cese-electrical-installation-details.edit', {
            parent: 'cese-electrical-installation-details',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details-dialog.html',
                    controller: 'CeseElectricalInstallationDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CeseElectricalInstallationDetails', function(CeseElectricalInstallationDetails) {
                            return CeseElectricalInstallationDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cese-electrical-installation-details', null, { reload: 'cese-electrical-installation-details' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cese-electrical-installation-details.delete', {
            parent: 'cese-electrical-installation-details',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cese-electrical-installation-details/cese-electrical-installation-details-delete-dialog.html',
                    controller: 'CeseElectricalInstallationDetailsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CeseElectricalInstallationDetails', function(CeseElectricalInstallationDetails) {
                            return CeseElectricalInstallationDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cese-electrical-installation-details', null, { reload: 'cese-electrical-installation-details' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
