(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .controller('CitizenServiceFormFieldDataController', CitizenServiceFormFieldDataController);

    CitizenServiceFormFieldDataController.$inject = ['CitizenServiceFormFieldData', 'CitizenServiceFormFieldDataByAppNo'];
    function CitizenServiceFormFieldDataController(CitizenServiceFormFieldData, CitizenServiceFormFieldDataByAppNo) {
        var vm = this;
        vm.getData = getData;
        vm.citizenServiceFormFieldData = [];
        function getData(appNo)
        {
           // alert(appNo);
            CitizenServiceFormFieldDataByAppNo.query({applicationNo: appNo}, function(result){
            vm.citizenServiceFormFieldData = result;
            })
        }

//        loadAll();
//
//        function loadAll() {
//            CitizenServiceFormFieldData.query(function(result) {
//                vm.citizenServiceFormFieldData = result;
//                vm.searchQuery = null;
//            });
//        }
    }
})();
