(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenServiceFormFieldDataDetailController', CitizenServiceFormFieldDataDetailController);

    CitizenServiceFormFieldDataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CitizenServiceFormFieldData'];

    function CitizenServiceFormFieldDataDetailController($scope, $rootScope, $stateParams, previousState, entity, CitizenServiceFormFieldData) {
        var vm = this;

        vm.citizenServiceFormFieldData = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:citizenServiceFormFieldDataUpdate', function(event, result) {
            vm.citizenServiceFormFieldData = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
