(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenProfileController', CitizenProfileController);

    CitizenProfileController.$inject = ['$timeout', '$state', 'Auth', '$scope', 'Principal', '$stateParams', 'Citizen','State', 'District', 'CitizenProfile', 'User','CheckContent'];

    function CitizenProfileController ($timeout, $state, Auth,  $scope, Principal, $stateParams, Citizen, State, District, CitizenProfile, User, CheckContent) {
        var vm = this;
        vm.x = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.save = save;
        vm.citizen = null;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.citizen =  Citizen.get({id: vm.account.id},function(result){
                $scope.StateSelected =result.stateId;
                $scope.SelectedDistrictId =result.districtId;
                vm.selectedStateId = $scope.StateSelected;
                vm.selectedDistrictId = $scope.SelectedDistrictId;

                State.get({id:  vm.selectedStateId},function(result){
                        vm.stateValue=result;
                        $scope.selectedState =  {id: result.id, stateName: result.stateName}

                    })
                vm.districts =  CitizenProfile.query({stateId : vm.selectedStateId},function(result){
                        vm.aa = result;
                    });
                District.get({id:  vm.selectedDistrictId},function(result){
                        vm.districtValue=result;
                        $scope.selectedDistrict =  {id: result.id, districtName: result.districtName}
                    })
                })
            });
        }

        vm.states = [];
        loadAll();
        function loadAll() {
            State.query(function(result) {
                vm.states = result;
                vm.searchQuery = null;
            });
        }

        vm.districts = [];
        loadAllDistrict();
        function loadAllDistrict() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
        }
        $scope.loadDistricts = function (stateObj) {
                   vm.districts = CitizenProfile.query({stateId : stateObj.id});
        }

        function save () {

         vm.citizen.stateId = $scope.selectedState.id;
         vm.citizen.districtId = $scope.selectedDistrict.id;

         if(vm.citizen.id!==null){
                Citizen.update(vm.citizen, onSaveSuccess, onSaveError);
            } else {
                Citizen.save(vm.citizen, onSaveSuccess, onSaveError);
            }
            vm.isSaving = true;

        }


        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:citizenUpdate', result);
//            $uibModalInstance.close(result);
            alert("Profile Edited.");
            vm.isSaving = false;

           // $state.go('home');
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))});


    }
})();
