(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('InstallationTestedByCESEController', InstallationTestedByCESEController);

    InstallationTestedByCESEController.$inject = ['InstallationTestedByCESE'];

    function InstallationTestedByCESEController(InstallationTestedByCESE) {

        var vm = this;

        vm.installationTestedByCESE = [];

        loadAll();

        function loadAll() {
            InstallationTestedByCESE.query(function(result) {
                vm.installationTestedByCESE = result;
                vm.searchQuery = null;
            });
        }
    }
})();
