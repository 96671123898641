(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('GovernmentTypeController', GovernmentTypeController);

    GovernmentTypeController.$inject = ['GovernmentType'];

    function GovernmentTypeController(GovernmentType) {

        var vm = this;

        vm.governmentTypes = [];

        loadAll();

        function loadAll() {
            GovernmentType.query(function(result) {
                vm.governmentTypes = result;
                vm.searchQuery = null;
            });
        }
    }
})();
