(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('department-profile', {
            parent: 'app',
            url: '/department-profile',
            data: {
                authorities: ['ROLE_HOD', 'ROLE_NODAL_OFFICER','ROLE_NODAL_LICENSE','ROLE_FIELD_OFFICER','ROLE_ADMIN','ROLE_USER'],
                pageTitle: 'Department Profile'
            },
            views: {
                'content@': {
                    templateUrl: 'app/department-profile/department-profile.html',
                    controller: 'DepartmentProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
//
            }
        })

    }

})();
