(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hepc-citizen-dashboard', {
            parent: 'app',
            url: '/hepc-citizen-dashboard?uname:investorname:address:useremail:country:state:city:mobile:project_site_district:projectid:serviceid:projectserviceid:citizenId:ceiserviceId:applicationNo',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/hepc-citizen-dashboard/fill-service-form-hepc.html',
                    controller: 'fillServiceFormHEPCController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                citizenServiceLog: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        comment: null,
                        applicationNo: null,
                        id: null
                    };
                },

                citizenServiceFormFieldData: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        formFieldId: null,
                        formFieldData: null,
                        applicationNo: null,
                        id: null
                    };
                },
                citizenServiceAttachment: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        attachmentTitle: null,
                        attachmentFile: null,
                        fileExtension: null,
                        applicationNo: null,
                        id: null
                    };
                },
                serviceFormAttachment: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        attachmentTitle: null,
                        attachmentFile: null,
                        fileExtension: null,
                        isDraft: null,
                        applicationNo: null,
                        id: null
                    };
                }

            }
        })
        .state('hepc', {
                    parent: 'app',
                    url: '/hepc?uname:investorname:address:useremail:country:state:city:mobile:project_site_district:projectid:serviceid:projectserviceid:citizenId:ceiserviceId:applicationNo',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/hepc-citizen-dashboard/hepc.html',
                            controller: 'HEPCController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {

                        serviceRequiredByHEPCUser: function () {
                            return {
                                uname: null,
                                investorname: null,
                                projectid: null,
                                serviceid: null,
                                projectserviceid: null,
                                requestType: null,
                                status: null,
                                useremail: null,
                                address: null,
                                mobile: null,
                                project_site_district:null,
                                citizenId:null,
                                ceiserviceId:null,
                                applicationNo:null,
                                id: null
                            };
                        },

                        citizenServiceLog: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                actionTaken: null,
                                actionTakenBy: null,
                                actionTakenDate: null,
                                comment: null,
                                applicationNo: null,
                                id: null
                            };
                        },

                        citizenServiceFormFieldData: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                formFieldId: null,
                                formFieldData: null,
                                applicationNo: null,
                                id: null
                            };
                        },
                        citizenServiceAttachment: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                fileExtension: null,
                                applicationNo: null,
                                id: null
                            };
                        },
                        serviceFormAttachment: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                fileExtension: null,
                                isDraft: null,
                                applicationNo: null,
                                id: null
                            };
                        }

                    }
                })

        .state('citizen-comment-hepc', {
            parent: 'hepc-citizen-dashboard',
            url: '/citizen-comment-hepc',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/hepc-citizen-dashboard/hepc-comment-dialog.html',
                    controller: 'hepcCitizenCommentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    keyboard: false,
                    resolve: {
                        citizenServiceLog: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                actionTaken: null,
                                actionTakenBy: null,
                                actionTakenDate: null,
                                comment: null,
                                id: null
                            };

                        },
                        citizenServiceAttachment: function () {
                            return {
                                serviceLogId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                attachmentExtension: null,
                                id: null
                            };
                        },
                        citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                            return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.ceiserviceId,pinCode:$stateParams.applicationNo}).$promise;
                        }]

                    }
               }).result.then(function() {
                   $state.go('hepc-citizen-dashboard', null, { reload: 'hepc-citizen-dashboard' });
               }, function() {
                   $state.go('^');
               });
            }]
        })

        .state('application-log-hepc', {
                    parent: 'hepc-citizen-dashboard',
                    url: '/application-log-hepc',
                    data: {
                        authorities: []
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                                templateUrl: 'app/hepc-citizen-dashboard/hepc-service-log-dialog.html',
                                controller: 'hepcServiceLogDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                keyboard:false,
                                size: 'lg',
                                resolve: {
                                    entity: ['CitizenServiceLogDetailsByApplicationNo', function(CitizenServiceLogDetailsByApplicationNo) {
                                        return CitizenServiceLogDetailsByApplicationNo.query({applicationNo:$stateParams.applicationNo});
                                    }]
                                }
                        }).result.then(function() {
//                            $state.go('hepc-citizen-dashboard', null);
                        }, function() {

                        });
                    }]
                })
//    .state('assign-chartered', {
//            parent: 'citizen-dashboard',
//            url: '/assign-chartered?:citizenId:serviceId:applicationNo',
//            data: {
//                authorities: []
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/citizen-dashboard/assign-chartered-dialog.html',
//                    controller: 'AssignCharteredController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                    citizenServiceLog: function () {
//                        return {
//                            citizenId: null,
//                            serviceId: null,
//                            actionTaken: null,
//                            actionTakenBy: null,
//                            actionTakenDate: null,
//                            comment: null,
//                            id: null
//                        };
//
//                    },
//                    citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
//                     return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
//                    }]
//                    }
//                }).result.then(function() {
//                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//                }, function() {
////                    $state.go('^');
//                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//                });
//            }]
//        })
//
//
//        .state('view-assigned-chartered', {
//                    parent: 'citizen-dashboard',
//                    url: '/view-assigned-chartered?:charteredId:citizenId:serviceId:applicationNo',
//                    data: {
//                        authorities: []
//                    },
//                    onEnter: ['$stateParams', '$state', '$uibModal','$window', function($stateParams, $state, $uibModal,$window) {
//                        $uibModal.open({
//                            templateUrl: 'app/citizen-dashboard/view-assigned-chartered-dialog.html',
//                            controller: 'ViewAssignCharteredController',
//                            controllerAs: 'vm',
//                            backdrop: 'static',
//                            size: 'lg',
//                            keyboard:false,
//                            resolve: {
//                                citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
//                                 return CitizenServiceDetailByCitizenService.query({charteredId:$stateParams.charteredId,citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
//                                }]
//                            }
//                        }).result.then(function() {
//                            $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//                        }, function() {
//                            $state.go('^');
//                        });
//                    }]
//                })
//
//        .state('application-log', {
//            parent: 'citizen-dashboard',
//            url: '/application-log?:applicationNo',
//            data: {
//                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/citizen-dashboard/service-log-dialog.html',
//                    controller: 'serviceLogDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    keyboard:false,
//                    size: 'lg',
//                    resolve: {
//                   entity: ['CitizenServiceLogDetailsByApplicationNo', function(CitizenServiceLogDetailsByApplicationNo) {
//                        return CitizenServiceLogDetailsByApplicationNo.query({applicationNo:$stateParams.applicationNo});
//                    }]
//                    }
//                }).result.then(function() {
//                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })
//
//        $stateProvider.state('citizen-service-fee-detail', {
//            parent: 'app',
//            url: '/citizen-service-fee-detail?:citizenId:serviceId:applicationNo',
//            data: {
//                authorities: []
//            },
//            views: {
//                'content@': {
//                    templateUrl: 'app/citizen-dashboard/citizen-service-fee-detail.html',
//                    controller: 'citizenServiceFeeDetailController',
//                    controllerAs: 'vm'
//                }
//            }
//        })
//
//
//
//       .state('service-payment', {
//        parent: 'citizen-dashboard',
//        url: '/service-payment?:citizenId:serviceId:feeAmount:id',
//        data: {
//            authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_RM','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
//        },
//        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//            $uibModal.open({
//                templateUrl: 'app/citizen-dashboard/service-payment-dialog.html',
//                controller: 'servicePaymentDialogController',
//                controllerAs: 'vm',
//                backdrop: 'static',
//                size: 'lg',
//                resolve: {
////                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
////                        $translatePartialLoader.addPart('home');
////                        return $translate.refresh();
////                    }],
////                    serviceLogList: ['GetServiceLog', function(GetServiceLog) {
////                        return GetServiceLog.query({projectid:$stateParams.projectid,serviceid:$stateParams.serviceid});
////                    }]
//                }
//            }).result.then(function() {
//                $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//            }, function() {
//                $state.go('^');
//            });
//        }]
//    })
//
//    .state('filledServiceForm-view', {
//        parent: 'citizen-dashboard',
//        url: '/filledserviceform?:citizenId:serviceId:applicationNo',
//           data: {
//               authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
//           },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//               $uibModal.open({
//                   templateUrl: 'app/service-clearance-action/serviceFormFilled.html',
//                   controller: 'serviceFormFilledController',
//                   controllerAs: 'vm',
//                   backdrop: 'static',
//                   size: 'lg',
//                   resolve: {
//                        departmentSrv: ['DepartmentSrv', function(DepartmentSrv) {
//                            return DepartmentSrv.get({id : $stateParams.serviceId}).$promise;
//                        }]
//
//                    }
//               }).result.then(function() {
//                     $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
//                 }, function() {
//                     $state.go('^');
//                 });
//            }]
//
//    });
    }

})();

