(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DepartmentProfileController', DepartmentProfileController);
    DepartmentProfileController.$inject = ['$timeout', '$scope', '$state', 'Principal', '$stateParams', 'State', 'District', 'Government', 'Department', 'DepartmentUserProfile', 'CitizenProfile','CheckContent'];
    function DepartmentProfileController ($timeout, $scope, $state , Principal, $stateParams, State, District, Government, Department, DepartmentUserProfile, CitizenProfile, CheckContent) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
       //vm.departmentUserProfile = [];
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;

                vm.isAuthenticated = Principal.isAuthenticated;
                vm.departmentUserProfile = DepartmentUserProfile.get({id : vm.account.id},function(result){
                console.log(vm.departmentUserProfile);
                $scope.departmentid = result.departmentId;


                $scope.stateId = result.state;

                $scope.districtId = result.districtId;

                vm.stateId = $scope.stateId;

                vm.districtId = $scope.districtId;

                vm.departid =  $scope.departmentid;

            Department.get({id: vm.departid},function(result){
                $scope.selectedDepartment = {id: result.id, departmentName: result.departmentName}
            })

            State.get({id: vm.stateId},function(result){
                $scope.selectedState = {id: result.id, stateName: result.stateName}
            })

            vm.districts =  CitizenProfile.query({stateId : vm.stateId},function(result){
                vm.aa = result;
            });

            District.get({id: vm.districtId},function(result){
                $scope.selectedDistrict = {id: result.id, districtName: result.districtName}
            })

            });
        })
       }


     function save () {
         vm.isSaving = true;
         vm.departmentUserProfile.departmentId = $scope.selectedDepartment.id;
         vm.departmentUserProfile.state = $scope.selectedState.id;
         vm.departmentUserProfile.districtId = $scope.selectedDistrict.id;
         vm.departmentUserProfile.createdBy = vm.departmentUserProfile.mobileNumber;

         DepartmentUserProfile.update(vm.departmentUserProfile, onSaveSuccess, onSaveError);
          alert("Your Profile edit Successfully");

     }

    function onSaveSuccess (result) {

            $scope.$emit('eServicesApp:departmentUserProfileUpdate', result);

    //            $uibModalInstance.close(result);
           vm.isSaving = false;
            $state.go('home');



        }

    function onSaveError () {
        vm.isSaving = false;
    }

    vm.states = [];
    loadAllState();
    function loadAllState() {
        State.query(function(result) {
            vm.states = result;
            vm.searchQuery = null;
        });
    }

    $scope.loadDistricts = function (stateObj) {
        vm.districts = CitizenProfile.query({stateId : stateObj.id})
    }

    loadAllDepartment();

    function loadAllDepartment() {
        Department.query(function(result) {
            vm.departments = result;
            vm.searchQuery = null;
        });
    }
    $scope.changeme = function() {
    if($scope.selectedDistrict.districtName == "Gurgaon")
    alert('Please Select the ');
    }
    vm.datePickerOpenStatus.createdDate = false;

    function openCalendar (date) {
        vm.datePickerOpenStatus[date] = true;
    }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))})
    }
})();
