(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CeseElectricalInstallationDetailsController', CeseElectricalInstallationDetailsController);

    CeseElectricalInstallationDetailsController.$inject = ['CeseElectricalInstallationDetails'];

    function CeseElectricalInstallationDetailsController(CeseElectricalInstallationDetails) {

        var vm = this;

        vm.ceseElectricalInstallationDetails = [];

        loadAll();

        function loadAll() {
            CeseElectricalInstallationDetails.query(function(result) {
                vm.ceseElectricalInstallationDetails = result;
                vm.searchQuery = null;
            });
        }
    }
})();
