(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('hepcServiceLogDialogController', hepcServiceLogDialogController);

    hepcServiceLogDialogController.$inject = ['$window', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'downloadService'];
    function hepcServiceLogDialogController ($window, $timeout, $scope, $stateParams, $uibModalInstance, entity, downloadService) {
        var vm = this;
        vm.citizenServiceLogDetailsByCitizen = entity;
        vm.clear = clear;
        vm.downloadFile=downloadFile;
//        vm.ServiceLog = serviceLogList;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();

        }
        function downloadFile(filename, downloadFileName)
            {
                downloadService.download(filename, downloadFileName)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
            }

    }
})();
