(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenservicefeeDeleteController',CitizenservicefeeDeleteController);

    CitizenservicefeeDeleteController.$inject = ['$uibModalInstance', 'entity', 'Citizenservicefee'];

    function CitizenservicefeeDeleteController($uibModalInstance, entity, Citizenservicefee) {
        var vm = this;

        vm.citizenservicefee = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Citizenservicefee.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
