(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('HEPCController', HEPCController);

     HEPCController.$inject = ['$window', '$sce','$timeout', 'Principal', '$scope', '$stateParams', 'DepartmentSrv', 'citizenServiceLog', 'serviceFormAttachment', 'ServiceFormAttachment', 'CitizenServiceDetailByCitizenService', 'CitizenServiceDetails', 'citizenServiceFormFieldData', 'ServiceFormFieldWithData', 'citizenServiceAttachment', 'CitizenServiceLog', 'CitizenServiceFormFieldData', 'FileManagement', 'ServiceFormAttachmentByApplicationNo', 'downloadService', 'Citizen', 'FeedbackFields','Auth', 'HepcUpdateStatus', 'serviceRequiredByHEPCUser', 'ProcessHEPCRequest'];

    function HEPCController($window, $sce,$timeout, Principal, $scope, $stateParams, DepartmentSrv, citizenServiceLog, serviceFormAttachment, ServiceFormAttachment, CitizenServiceDetailByCitizenService, CitizenServiceDetails, citizenServiceFormFieldData, ServiceFormFieldWithData, citizenServiceAttachment, CitizenServiceLog, CitizenServiceFormFieldData, FileManagement, ServiceFormAttachmentByApplicationNo, downloadService, Citizen, FeedbackFields, Auth, HepcUpdateStatus, serviceRequiredByHEPCUser,ProcessHEPCRequest) {

        var vm = this;
        vm.account = null;
        vm.servicesRequiredByHEPCUser = serviceRequiredByHEPCUser;
        var uname = $stateParams.uname;
        var investorname = $stateParams.investorname;
        var address = $stateParams.address;
        var useremail = $stateParams.useremail;
        var country = $stateParams.country;
        var state = $stateParams.state;
        var requestType = $stateParams.requestType;
        var mobile = $stateParams.mobile;
        var project_site_district = $stateParams.project_site_district;
        var projectid = $stateParams.projectid;
        var serviceid = $stateParams.serviceid;
        var projectserviceid = $stateParams.projectserviceid;
        var useremail = $stateParams.useremail;
        var address = $stateParams.address;
        var mobile = $stateParams.mobile;
        var project_site_district = $stateParams.project_site_district;
        var uname = "";
        var applicationNo = "";
        var citizenId = "";
        var ceiserviceId = "";

        vm.applicationNo = $stateParams.applicationNo;
        vm.isrequestProcessed = false;

        processRequest();

        function processRequest() {
            vm.isSaving = true;
            vm.servicesRequiredByHEPCUser.uname = uname;
            vm.servicesRequiredByHEPCUser.investorname = investorname;
            vm.servicesRequiredByHEPCUser.projectid = projectid;
            vm.servicesRequiredByHEPCUser.serviceid = serviceid;
            vm.servicesRequiredByHEPCUser.projectserviceid = projectserviceid;
            vm.servicesRequiredByHEPCUser.requestType = requestType;
            vm.servicesRequiredByHEPCUser.useremail = useremail;
            vm.servicesRequiredByHEPCUser.address = address;
            vm.servicesRequiredByHEPCUser.mobile = mobile;
            vm.servicesRequiredByHEPCUser.project_site_district = project_site_district;

//            vm.servicesRequiredByHEPCUser.status = status;
            ProcessHEPCRequest.save(vm.servicesRequiredByHEPCUser, onSuccess, onError);
        }
        function onSuccess (result) {
            console.log(result);


            uname = result.uname;
            citizenId = result.citizenId;
            ceiserviceId = result.ceiserviceId;
            applicationNo = result.applicationNo;

             vm.isrequestProcessed = true;
             $window.location.href = "https://egovservices.in/#/hepc-citizen-dashboard?uname=" + uname +"&projectid=" + projectid +"&serviceid=" + serviceid +"&projectserviceid="+ projectserviceid +"&project_site_district=" + project_site_district +"&investorname=" + investorname +"&useremail=" + useremail +"&requestType="+ requestType + "&mobile=" + mobile +"&citizenId="+ citizenId +"&ceiserviceId=" + ceiserviceId + "&applicationNo=" + applicationNo;
        }

        function onError () {
            vm.isSaving = false;
        }


    }
})();
