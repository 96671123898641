(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('citizenGivenApplicationForm', citizenGivenApplicationForm);

     citizenGivenApplicationForm.$inject = ['Principal', '$scope', '$stateParams', 'ServiceFormFieldWithData', 'ServiceFormAttachmentByApplicationNo', 'downloadService'];

    function citizenGivenApplicationForm( Principal, $scope, $stateParams, ServiceFormFieldWithData, ServiceFormAttachmentByApplicationNo, downloadService) {

        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.serviceFormFields = [];
        vm.applicationNo = $stateParams.applicationNo;
//        $scope.$sce = $sce;
        vm.downloadFile=downloadFile;
        getAccount();
        vm.serviceName = $stateParams.serviceName;
        alert($stateParams.serviceName);
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadServiceFormDetails();
            });
        }

//        $timeout(function (){
//            angular.element('.form-group:eq(1)>input').focus();
//        });

        function clear () {
//            $uibModalInstance.dismiss('cancel');
        }

        function loadServiceFormDetails() {
            vm.serviceFormFields = ServiceFormFieldWithData.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,applicationNo:$stateParams.applicationNo});

        }
        vm.serviceFormAttachments = [];

        loadServiceAttachments();

        function loadServiceAttachments() {
                vm.serviceFormAttachments = ServiceFormAttachmentByApplicationNo.query({applicationNo:$stateParams.applicationNo,citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId});
//                console.log(vm.serviceFormAttachments);
        }

        function downloadFile(filename, downloadFileName)
        {
            alert(downloadFileName);
            downloadService.download(filename, downloadFileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }


    }
})();
