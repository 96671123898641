(function() {
    'use strict';

    angular
    .module('eServicesApp')
    .factory('GetImageForView', ['$q', '$timeout', '$window',
        function($q, $timeout, $window, $http) {
            return {
                getImageSource: function(name) {
                    return 'api/download?name=' + name;
                }
            };
        }
    ]);
})();
