(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'NotificationByLogin', '$scope'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, NotificationByLogin, $scope) {
        var vm = this;
        var flag = false;
        vm.hidenavbar = true;
        vm.isNavbarCollapsed = true;

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(vm.account!=null){
                    $scope.auth = account.authorities;
//                vm.role = vm.account.authorities[0];
                    getNotification();
                }
            });
        }


        function getNotification(){
            NotificationByLogin.query({action_taken_on: vm.account.login},function(result){
                vm.notification = result;
                vm.notificationCount = vm.notification.length;
            })
        }

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
