(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-service-field-option', {
            parent: 'entity',
            url: '/citizen-service-field-option',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceFieldOptions'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-options.html',
                    controller: 'CitizenServiceFieldOptionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizen-service-field-option-detail', {
            parent: 'citizen-service-field-option',
            url: '/citizen-service-field-option/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceFieldOption'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-option-detail.html',
                    controller: 'CitizenServiceFieldOptionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CitizenServiceFieldOption', function($stateParams, CitizenServiceFieldOption) {
                    return CitizenServiceFieldOption.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizen-service-field-option',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizen-service-field-option-detail.edit', {
            parent: 'citizen-service-field-option-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-option-dialog.html',
                    controller: 'CitizenServiceFieldOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceFieldOption', function(CitizenServiceFieldOption) {
                            return CitizenServiceFieldOption.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-field-option.new', {
            parent: 'citizen-service-field-option',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-option-dialog.html',
                    controller: 'CitizenServiceFieldOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                formFieldDataId: null,
                                formFieldOption: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizen-service-field-option', null, { reload: 'citizen-service-field-option' });
                }, function() {
                    $state.go('citizen-service-field-option');
                });
            }]
        })
        .state('citizen-service-field-option.edit', {
            parent: 'citizen-service-field-option',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-option-dialog.html',
                    controller: 'CitizenServiceFieldOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceFieldOption', function(CitizenServiceFieldOption) {
                            return CitizenServiceFieldOption.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-field-option', null, { reload: 'citizen-service-field-option' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-field-option.delete', {
            parent: 'citizen-service-field-option',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-field-option/citizen-service-field-option-delete-dialog.html',
                    controller: 'CitizenServiceFieldOptionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CitizenServiceFieldOption', function(CitizenServiceFieldOption) {
                            return CitizenServiceFieldOption.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-field-option', null, { reload: 'citizen-service-field-option' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
