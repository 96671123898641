(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DepartmentSrvDetailController', DepartmentSrvDetailController);

    DepartmentSrvDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DepartmentSrv'];

    function DepartmentSrvDetailController($scope, $rootScope, $stateParams, previousState, entity, DepartmentSrv) {
        var vm = this;

        vm.departmentSrv = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:departmentSrvUpdate', function(event, result) {
            vm.departmentSrv = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
