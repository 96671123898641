(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service-form-attachment', {
            parent: 'entity',
            url: '/service-form-attachment',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ServiceFormAttachments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachments.html',
                    controller: 'ServiceFormAttachmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('service-form-attachment-detail', {
            parent: 'service-form-attachment',
            url: '/service-form-attachment/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ServiceFormAttachment'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachment-detail.html',
                    controller: 'ServiceFormAttachmentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ServiceFormAttachment', function($stateParams, ServiceFormAttachment) {
                    return ServiceFormAttachment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'service-form-attachment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('service-form-attachment-detail.edit', {
            parent: 'service-form-attachment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachment-dialog.html',
                    controller: 'ServiceFormAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceFormAttachment', function(ServiceFormAttachment) {
                            return ServiceFormAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-form-attachment.new', {
            parent: 'service-form-attachment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachment-dialog.html',
                    controller: 'ServiceFormAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                fileExtension: null,
                                isDraft: null,
                                applicationNo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('service-form-attachment', null, { reload: 'service-form-attachment' });
                }, function() {
                    $state.go('service-form-attachment');
                });
            }]
        })
        .state('service-form-attachment.edit', {
            parent: 'service-form-attachment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachment-dialog.html',
                    controller: 'ServiceFormAttachmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceFormAttachment', function(ServiceFormAttachment) {
                            return ServiceFormAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-form-attachment', null, { reload: 'service-form-attachment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-form-attachment.delete', {
            parent: 'service-form-attachment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-form-attachment/service-form-attachment-delete-dialog.html',
                    controller: 'ServiceFormAttachmentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ServiceFormAttachment', function(ServiceFormAttachment) {
                            return ServiceFormAttachment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-form-attachment', null, { reload: 'service-form-attachment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
