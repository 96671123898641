(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DistrictController', DistrictController);

    DistrictController.$inject = ['State', 'District', 'GenerateAppNo'];

    function DistrictController(State, District, GenerateAppNo) {

        var vm = this;

        vm.districts = [];
        vm.states = [];

        vm.getAppNo = getAppNo;

        loadAll();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
            State.query(function(result) {
                vm.states = result;
                vm.searchQuery = null;
            });
        }

        function getAppNo() {
            GenerateAppNo.query();
        }

    }
})();
