(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('authenticate-chartered', {
            parent: 'app',
            url: '/authenticate-chartered/:status',
            data: {
                authorities: ['ROLE_HOD','ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/authenticate-chartered/authenticate-chartered.html',
                    controller: 'AuthenticateChartered',
                    controllerAs: 'vm'
                }
            }
        })


        .state('view-chartered-citizen', {
            parent: 'authenticate-chartered',
            url: '/view-chartered-citizen?:citizenId',
            data: {
                authorities: ['ROLE_HOD','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','$window', function($stateParams, $state, $uibModal,$window) {
                $uibModal.open({
                    templateUrl: 'app/authenticate-chartered/view-chartered-citizen-dialog.html',
                    controller: 'ViewCharteredCitizen',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    keyboard:false,
                    resolve: {
                        citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                         return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('authenticate-chartered', null, { reload: 'authentcate-chartered' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();
