(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('citizenServiceFeeUpdatedController', citizenServiceFeeUpdatedController);

    citizenServiceFeeUpdatedController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','citizenServiceFee', 'Citizenservicefee', 'CitizenservicefeebyApplicationNo'];
    function citizenServiceFeeUpdatedController ($timeout, $scope, $stateParams, $uibModalInstance, citizenServiceFee, Citizenservicefee, CitizenservicefeebyApplicationNo ) {
        var vm = this;
        vm.clear = clear;
        vm.citizenServiceFee = citizenServiceFee;
        vm.savecustomerServiceFee = savecustomerServiceFee;
        vm.deleteRecord = deleteRecord;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }



        function deleteRecord(recordid){
            Citizenservicefee.delete({id:recordid});
            clear ();
        }
        loadCitizenServiceFee();

        function loadCitizenServiceFee(){
            vm.citizenServiceFees = CitizenservicefeebyApplicationNo.query({applicationNo:$stateParams.applicationNo,citizenId:$stateParams.citizenId});
        }

        function savecustomerServiceFee () {
            vm.isSaving = true;
            vm.citizenServiceFee.citizenId = $stateParams.citizenId;
            vm.citizenServiceFee.serviceId = $stateParams.serviceId;
            vm.citizenServiceFee.applicationNo = $stateParams.applicationNo;
            vm.citizenServiceFee.serviceFeeResponse = "Unpaid";
            if (vm.citizenServiceFee.Id == null) {
                Citizenservicefee.save(vm.citizenServiceFee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
//            $scope.$emit('eServicesApp:citizenservicefeeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
