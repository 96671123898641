(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('CitizenServiceLogDetailsByApplicationNo', CitizenServiceLogDetailsByApplicationNo);

    CitizenServiceLogDetailsByApplicationNo.$inject = ['$resource'];

    function CitizenServiceLogDetailsByApplicationNo ($resource) {
        var resourceUrl =  'api/application-logs-details/:applicationNo';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();

