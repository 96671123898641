(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServiceFormFieldDialogController', ServiceFormFieldDialogController);

    ServiceFormFieldDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DepartmentSrv', 'ServiceFormField'];

    function ServiceFormFieldDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DepartmentSrv, ServiceFormField) {
        var vm = this;

        vm.serviceFormField = entity;
        vm.clear = clear;
        vm.save = save;

        loadAll();

        function loadAll() {
            DepartmentSrv.query(function(result) {
                vm.departmentServices = result;
                console.log(vm.departmentServices);
                vm.searchQuery = null;
            });
        }

        load();
                                 function load () {

                                               vm.id = vm.serviceFormField.departmentId;
                                                  DepartmentSrv.get({id: vm.id},function(result){
                                                  vm.servicevalue=result;

                                                  $scope.selectedService = {id: result.id, serviceName: result.serviceName}
                                                        });
                                        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.serviceFormField.departmentId = $scope.selectedService.id;

            if (vm.serviceFormField.id !== null) {
                ServiceFormField.update(vm.serviceFormField, onSaveSuccess, onSaveError);
            } else {
                ServiceFormField.save(vm.serviceFormField, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:serviceFormFieldUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
