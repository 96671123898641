(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenServiceLogAttachmentDetailController', CitizenServiceLogAttachmentDetailController);

    CitizenServiceLogAttachmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CitizenServiceLogAttachment'];

    function CitizenServiceLogAttachmentDetailController($scope, $rootScope, $stateParams, previousState, entity, CitizenServiceLogAttachment) {
        var vm = this;

        vm.citizenServiceLogAttachment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:citizenServiceLogAttachmentUpdate', function(event, result) {
            vm.citizenServiceLogAttachment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
