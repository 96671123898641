(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('fillServiceFormController', fillServiceFormController);

     fillServiceFormController.$inject = ['$sce','$timeout', 'Principal', '$scope', '$stateParams', 'DepartmentSrv', 'citizenServiceLog', 'serviceFormAttachment', 'ServiceFormAttachment', 'CitizenServiceDetailByCitizenService', 'CitizenServiceDetails', 'citizenServiceFormFieldData', 'ServiceFormFieldWithData', 'citizenServiceAttachment', 'CitizenServiceLog', 'CitizenServiceFormFieldData', 'FileManagement', 'ServiceFormAttachmentByApplicationNo', 'downloadService', 'Citizen', 'FeedbackFields','CheckContent'];

    function fillServiceFormController($sce,$timeout, Principal, $scope, $stateParams, DepartmentSrv, citizenServiceLog, serviceFormAttachment, ServiceFormAttachment, CitizenServiceDetailByCitizenService, CitizenServiceDetails, citizenServiceFormFieldData, ServiceFormFieldWithData, citizenServiceAttachment, CitizenServiceLog, CitizenServiceFormFieldData, FileManagement, ServiceFormAttachmentByApplicationNo, downloadService, Citizen, FeedbackFields, CheckContent) {

        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.serviceFormFields = [];
        $scope.$sce = $sce;
        vm.serviceFormAttachment = serviceFormAttachment;
        vm.uploadAttachment = uploadAttachment;
        vm.saveFormFieldData = saveFormFieldData;
        vm.citizenServiceLog = citizenServiceLog;
        vm.citizenServiceFormFieldData = citizenServiceFormFieldData;
        vm.citizenServiceAttachment = citizenServiceAttachment;
        var projectServiceLogid=null;
        var formfielddataObjectArrayLength = 0;
        var objectCount = 0;
        var saveLogFlag = false;
        var formFieldDataValues="";
        var actionOnForm = "Form Filled";
        var serviceFormAttachemntObject = null;
        vm.downloadFile=downloadFile;
        vm.deleteAttachment = deleteAttachment;
        vm.citizenServiceDetail = null;
        vm.doubleextension = true;
        vm.feedback = {};
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;

                vm.isAuthenticated = Principal.isAuthenticated;
                loadServiceFormDetails();
            });
        }

            $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.submitForm = function(){
            $log.debug($scope.entity);
        }


        vm.departmentSrv = DepartmentSrv.get({id : $stateParams.serviceId});

        vm.citizenServiceDetail = CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo});

        function loadServiceFormDetails() {
            vm.serviceFormFields = ServiceFormFieldWithData.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,applicationNo:$stateParams.applicationNo});

        }
        function uploadAttachment () {
            var fileNameArray = vm.serviceFormAttachemnt.file.name.split(".");
            var fileextension = "";
            if ((fileNameArray.length < 3)) {
                if (fileNameArray[0] != "") {
                    if ((fileNameArray.length > 1)) {
                        fileextension = fileNameArray[(fileNameArray.length - 1)];
                    }
                    var inputFileName = vm.serviceFormAttachment.attachmentTitle;
                    if (fileextension !== "") {
                        inputFileName = inputFileName + "." + fileextension;
                    }

                    vm.isSaving = true;
                    vm.serviceFormAttachment.citizenId = $stateParams.citizenId;
                    vm.serviceFormAttachment.serviceId = $stateParams.serviceId;
                    vm.serviceFormAttachment.applicationNo = $stateParams.applicationNo;
                    ServiceFormAttachment.update(vm.serviceFormAttachment, onUploadAttachmentSuccess, onUploadAttachmentError);
                }
                else {
                    alert("Invalid File Name");
                }

            }
            else {
                alert("Please select correct file format");
                vm.doubleextension = false;
            }
        }

        function onUploadAttachmentSuccess (result) {
           $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
//           $uibModalInstance.close(result);

           var filename = result.id;
           var file =vm.serviceFormAttachemnt.file;
           FileManagement.saveFile(file,filename);
           serviceFormAttachemntObject=result;
           var fileNameArray = vm.serviceFormAttachemnt.file.name.split(".");
           var fileextension="";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName=result.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           serviceFormAttachemntObject.attachmentFile=inputFileName;
           serviceFormAttachemntObject.fileExtension = fileextension;
           ServiceFormAttachment.update(serviceFormAttachemntObject, onServiceFormAttachemntSuccess, onServiceFormAttachemntError);
           loadServiceAttachments();
           vm.isSaving = false;
       }

       function onUploadAttachmentError () {
           vm.isSaving = false;
       }

       function onServiceFormAttachemntSuccess (result) {
          $scope.$emit('eServicesApp:serviceFormAttachmentUpdate', result);
//          $uibModalInstance.close(result);
          loadServiceAttachments();
          vm.isSaving = false;

      }

    function onServiceFormAttachemntError () {
       vm.isSaving = false;
    }

    var vmlength = null;

    function saveFormFieldData()
    {

        vmlength = vm.serviceFormFields.length;
        for(var i= 0; i<vmlength;i++)
        {
            var data = vm.serviceFormFields[i];
            formFieldDataValues=formFieldDataValues + vm.serviceFormFields[i].formFieldData+" | ";
            vm.isSaving = true;
             if (data.id !== null) {
                actionOnForm = "Edited Form";
                CitizenServiceFormFieldData.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
            } else {
                CitizenServiceFormFieldData.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
            }
            objectCount = i;
        }
    }
        function onSaveServiceFormFieldDataSuccess(result)
        {
            if(!saveLogFlag)
            {
                if(objectCount ===(vmlength-1))
                saveServiceLog();
                alert(actionOnForm +" Successfully!");
                console.log(result);
                if(result.required == true){
                    vm.feedback.applicationNo = result.applicationNo;
                    vm.feedback.serviceId = result.serviceId;
                    vm.feedback.citizenId = result.citizenId;
                    FeedbackFields.get({applicationno: vm.feedback.applicationNo, citizenid: vm.feedback.citizenId, serviceid: vm.feedback.serviceId},onFeedbackSuccess,onFeedbackError);

                }
                saveLogFlag = true;
            }

        }

        function onFeedbackSuccess(){
            console.log("feedback success");
        }
        function onFeedbackError(){
            console.log("feedback error");
        }

        function onSaveServiceFormFieldDataError()
        {
        }

        function saveServiceLog(){

            vm.citizenServiceLog.actionTaken = actionOnForm;
            vm.citizenServiceLog.comment = formFieldDataValues;
            vm.citizenServiceLog.citizenId = $stateParams.citizenId;
            vm.citizenServiceLog.serviceId = $stateParams.serviceId;
            vm.citizenServiceLog.applicationNo = $stateParams.applicationNo;
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
        }
        function onSaveServiceLogSuccess(result)
        {
            var citizenServiceDetailObject = vm.citizenServiceDetail[0];
            citizenServiceDetailObject.lastComment=actionOnForm;
            citizenServiceDetailObject.isFormFilled=true;
            CitizenServiceDetails.update(citizenServiceDetailObject,onUpdateCitizenServiceDetailSuccess,onUpdateCitizenServiceDetailError);
        }

        function onUpdateCitizenServiceDetailSuccess(result)
        {

            vm.isSaving = false;

        }

        function onUpdateCitizenServiceDetailError()
        {
            vm.isSaving = false;
        }



        function onSaveServiceLogError(result)
        {

        }

        vm.serviceFormAttachments = [];

        loadServiceAttachments();

        function loadServiceAttachments() {
            vm.serviceFormAttachments = ServiceFormAttachmentByApplicationNo.query({applicationNo:$stateParams.applicationNo,citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId});

        }

        function downloadFile(filename, downloadFileName)
        {
            downloadService.download(filename, downloadFileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function deleteAttachment (id) {
            ServiceFormAttachment.delete({id: id},
                function () {
                    loadServiceAttachments();
                });
        }

        $('input').each(function(){ CheckContent.checkInput($(this))});
        $('textarea').each(function() { CheckContent.checkInput($(this))});

    }
})();
