(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance','userByEmail'];

    function LoginController ($rootScope, $state, $timeout, Auth, $uibModalInstance, userByEmail) {
        var vm = this;

        vm.authenticationError = false;
        vm.emailError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            event.preventDefault();

            vm.userByEmail =  userByEmail.get({email:vm.username},function (result){
                     vm.emailError = false;
                     Auth.login({
                         username: result.login,
                         password: new AesUtil().encryptPassword(vm.password),
                         rememberMe: vm.rememberMe
                     }).then(function () {
                         vm.authenticationError = false;
                         $uibModalInstance.close();
                         if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                             $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                             $state.go('home');
                         }

                         $rootScope.$broadcast('authenticationSuccess');

                         // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                         // since login is successful, go to stored previousState and clear previousState
                         if (Auth.getPreviousState()) {
                             var previousState = Auth.getPreviousState();
                             Auth.resetPreviousState();
                             $state.go(previousState.name, previousState.params);
                         }
                     }).catch(function () {
                         vm.authenticationError = true;
                     });
                 }, function (error)
                 {
                    vm.authenticationError = false;
                    vm.emailError = true;
                 });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
