(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CeseElectricalInstallationDetailsDeleteController',CeseElectricalInstallationDetailsDeleteController);

    CeseElectricalInstallationDetailsDeleteController.$inject = ['$uibModalInstance', 'entity', 'CeseElectricalInstallationDetails'];

    function CeseElectricalInstallationDetailsDeleteController($uibModalInstance, entity, CeseElectricalInstallationDetails) {
        var vm = this;

        vm.ceseElectricalInstallationDetails = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CeseElectricalInstallationDetails.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
