(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('department-user-profile', {
            parent: 'entity',
            url: '/department-user-profile',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'DepartmentUserProfiles'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/department-user-profile/department-user-profiles.html',
                    controller: 'DepartmentUserProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('department-user-profile-detail', {
            parent: 'department-user-profile',
            url: '/department-user-profile/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'DepartmentUserProfile'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/department-user-profile/department-user-profile-detail.html',
                    controller: 'DepartmentUserProfileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DepartmentUserProfile', function($stateParams, DepartmentUserProfile) {
                    return DepartmentUserProfile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'department-user-profile',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('department-user-profile-detail.edit', {
            parent: 'department-user-profile-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/department-user-profile/department-user-profile-dialog.html',
                    controller: 'DepartmentUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DepartmentUserProfile', function(DepartmentUserProfile) {
                            return DepartmentUserProfile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('department-user-profile.new', {
            parent: 'department-user-profile',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/department-user-profile/department-user-profile-dialog.html',
                    controller: 'DepartmentUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                departmentId: null,
                                userLogin: null,
                                authorties: null,
                                firstName: null,
                                lastName: null,
                                designation: null,
                                mobileNumber: null,
                                phoneNumber: null,
                                email: null,
                                state: null,
                                districtId: null,
                                city: null,
                                address1: null,
                                address2: null,
                                pinCode: null,
                                createdBy: null,
                                createdDate: null,
                                juridictionArea: null,
                                aadhaar: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('department-user-profile', null, { reload: 'department-user-profile' });
                }, function() {
                    $state.go('department-user-profile');
                });
            }]
        })
        .state('department-user-profile.edit', {
            parent: 'department-user-profile',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/department-user-profile/department-user-profile-dialog.html',
                    controller: 'DepartmentUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DepartmentUserProfile', function(DepartmentUserProfile) {
                            return DepartmentUserProfile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('department-user-profile', null, { reload: 'department-user-profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('department-user-profile.delete', {
            parent: 'department-user-profile',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/department-user-profile/department-user-profile-delete-dialog.html',
                    controller: 'DepartmentUserProfileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DepartmentUserProfile', function(DepartmentUserProfile) {
                            return DepartmentUserProfile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('department-user-profile', null, { reload: 'department-user-profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
