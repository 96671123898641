(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServicesRequiredByHEPCUserController', ServicesRequiredByHEPCUserController);

    ServicesRequiredByHEPCUserController.$inject = ['ServicesRequiredByHEPCUser'];

    function ServicesRequiredByHEPCUserController(ServicesRequiredByHEPCUser) {

        var vm = this;

        vm.servicesRequiredByHEPCUsers = [];

        loadAll();

        function loadAll() {
            ServicesRequiredByHEPCUser.query(function(result) {
                vm.servicesRequiredByHEPCUsers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
