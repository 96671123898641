(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ServicesRequiredByHEPCUserDialogController', ServicesRequiredByHEPCUserDialogController);

    ServicesRequiredByHEPCUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ServicesRequiredByHEPCUser'];

    function ServicesRequiredByHEPCUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ServicesRequiredByHEPCUser) {
        var vm = this;

        vm.servicesRequiredByHEPCUser = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.servicesRequiredByHEPCUser.id !== null) {
                ServicesRequiredByHEPCUser.update(vm.servicesRequiredByHEPCUser, onSaveSuccess, onSaveError);
            } else {
                ServicesRequiredByHEPCUser.save(vm.servicesRequiredByHEPCUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:servicesRequiredByHEPCUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
