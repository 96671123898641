(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('User_kyc_verificationDetailController', User_kyc_verificationDetailController);

    User_kyc_verificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'User_kyc_verification'];

    function User_kyc_verificationDetailController($scope, $rootScope, $stateParams, previousState, entity, User_kyc_verification) {
        var vm = this;

        vm.user_kyc_verification = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('eServicesApp:user_kyc_verificationUpdate', function(event, result) {
            vm.user_kyc_verification = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
