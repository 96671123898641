(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('ceseInstallationTestedReportAllController', ceseInstallationTestedReportAllController);

    ceseInstallationTestedReportAllController.$inject = ['$scope', '$stateParams','InstallationTestedByAllCESE'];

    function ceseInstallationTestedReportAllController($scope, $stateParams,InstallationTestedByAllCESE) {

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        var vm = this;
        vm.selectedValue = "10";
//        vm.installationTestedByAllCESE = entity;
//        vm.clear = clear;
//        $timeout(function (){
//            angular.element('.form-group:eq(1)>input').focus();
//        });
//
//        function clear () {
//            $uibModalInstance.dismiss('cancel');
//        }

        vm.installationTestedByAllCESE = InstallationTestedByAllCESE.get({uploadedreportmonthyear : "JUNE-2018", login:$stateParams.login});

        console.log(vm.installationTestedByAllCESE);

//        loadAll();
//
//        function loadAll() {
//            InstallationTestedByAllCESE.query(function(result) {
//                vm.installationTestedByCESE = result;
//                vm.searchQuery = null;
//            });
//        }
//
//        $scope.setPageSize = function (pageSizeVal) {
//
//            $scope.pageSize = pageSizeVal;
//
//        }
//
//        $scope.pageChangeHandler = function(num) {
//
//        };
    }
})();
