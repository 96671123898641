(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DepartmentSrvDialogController', DepartmentSrvDialogController);

    DepartmentSrvDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DepartmentSrv', 'Department'];

    function DepartmentSrvDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DepartmentSrv, Department) {
        var vm = this;

        vm.departmentSrv = entity;
        vm.clear = clear;
        vm.save = save;

        vm.departments = [];

        loadAll();

        function loadAll() {
            Department.query(function(result) {
                vm.departments = result;
                vm.searchQuery = null;
            });
        }

        load();
                                  function load () {
                                               vm.id = vm.departmentSrv.departmentId;
                                              // alert(vm.id);
                                               Department.get({id: vm.id},function(result){
                                                     vm.districtvalue=result;
                                                     $scope.selectedDepartment = {id: result.id, departmentName: result.departmentName}
                                                                });
                                                }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.departmentSrv.departmentId = $scope.selectedDepartment.id;
            if (vm.departmentSrv.id !== null) {
                DepartmentSrv.update(vm.departmentSrv, onSaveSuccess, onSaveError);
            } else {
                DepartmentSrv.save(vm.departmentSrv, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:departmentSrvUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
