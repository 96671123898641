(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('notification', {
            parent: 'entity',
            url: '/notification',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Notifications'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/notification/notifications.html',
                    controller: 'NotificationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('notification-detail', {
            parent: 'notification',
            url: '/notification/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Notification'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/notification/notification-detail.html',
                    controller: 'NotificationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Notification', function($stateParams, Notification) {
                    return Notification.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'notification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('notification-detail.edit', {
            parent: 'notification-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/notification/notification-dialog.html',
                    controller: 'NotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Notification', function(Notification) {
                            return Notification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('notification.new', {
            parent: 'notification',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/notification/notification-dialog.html',
                    controller: 'NotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                application_number: null,
                                action_taken_on: null,
                                action_taken_by: null,
                                action_taken_subject: null,
                                action_taken_detail: null,
                                action_taken_date: null,
                                action_taken_isread: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('notification', null, { reload: 'notification' });
                }, function() {
                    $state.go('notification');
                });
            }]
        })
        .state('notification.edit', {
            parent: 'notification',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/notification/notification-dialog.html',
                    controller: 'NotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Notification', function(Notification) {
                            return Notification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('notification', null, { reload: 'notification' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('notification.delete', {
            parent: 'notification',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/notification/notification-delete-dialog.html',
                    controller: 'NotificationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Notification', function(Notification) {
                            return Notification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('notification', null, { reload: 'notification' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

//        .state('notification-details', {
//                    parent: 'notification',
//                    url: '/notification-details',
//                    data: {
//                       // authorities: ['ROLE_USER']
//                    },
//                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                        $uibModal.open({
//                            templateUrl: 'app/notification-details/notification-details.html',
//                            controller: 'NotificationDetailsController',
//                            controllerAs: 'vm',
//                            backdrop: 'static',
//                            size: 'lg',
//                            resolve: {
////                                entity: ['Notification', function(Notification) {
////                                    return Notification.get({id : $stateParams.id}).$promise;
////                                }]
//                            }
//                        }).result.then(function() {
//                            $state.go('^', {}, { reload: false });
//                        }, function() {
//                            $state.go('^');
//                        });
//                    }]
//                })


                 .state('notification-details', {
                            parent: 'notification',
                            url: '/notification-details/{id}',
                            data: {
//                                authorities: ['ROLE_USER'],
                                pageTitle: 'Notification'
                            },
                            views: {
                                'content@': {
                                    templateUrl: 'app/notification-details/notification-details.html',
                                    controller: 'NotificationDetailsController',
                                    controllerAs: 'vm'
                                }
                            },
                            resolve: {
                                entity: ['$stateParams', 'Notification', function($stateParams, Notification) {
                                    return Notification.get({id : $stateParams.id}).$promise;
                                }],
                                previousState: ["$state", function ($state) {
                                    var currentStateData = {
                                        name: $state.current.name || 'notification',
                                        params: $state.params,
                                        url: $state.href($state.current.name, $state.params)
                                    };
                                    return currentStateData;
                                }]
                            }
                        });
    }

})();
