(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-profile', {
            parent: 'app',
            url: '/citizen-profile',
            data: {
                authorities: ['ROLE_USER','ROLE_CHARTERED', 'ROLE_LICENSE_USER'],
                pageTitle: 'Citizens'
            },
            views: {
                'content@': {
                    templateUrl: 'app/citizen-profile/citizen-profile.html',
                    controller: 'CitizenProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
//                entity: function () {
//                    return {
//                        citizenFirstName: null,
//                        citizenMiddleName: null,
//                        citizenLastName: null,
//                        stateId: null,
//                        city: null,
//                        districtId: null,
//                        mobileNumber: null,
//                        phoneNumber: null,
//                        emailId: null,
//                        address1: null,
//                        address2: null,
//                        pinCode: null,
//                        loginName: null,
//                        id: null
//                    };
//                },
//                user: function () {
//                    return {
//                        id: null, login: null, firstName: null, lastName: null, email: null,
//                        activated: true, langKey: null, createdBy: null, createdDate: null,
//                        lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
//                        resetKey: null, authorities: null
//                    };
//                }

            }
        })

        .state('citizen-profile.edit', {
            parent: 'citizen',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER', 'ROLE_LICENSE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen/citizen-dialog.html',
                    controller: 'CitizenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Citizen', function(Citizen) {
                            return Citizen.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen', null, { reload: 'citizen' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

    }

})();
