(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('inspectionReportController', inspectionReportController);

    inspectionReportController.$inject = ['$timeout', '$state', 'Auth', '$scope', '$stateParams', '$uibModalInstance', 'Principal', 'entity','citizenServiceDetail', 'District', 'CitizenProfile', 'User', 'CeseElectricalInstallationDetails', 'FileManagement', 'CitizenServiceDetails','CitizenServiceDetailByCitizenService'];

    function inspectionReportController ($timeout, $state, Auth,  $scope, $stateParams, $uibModalInstance, Principal, entity, citizenServiceDetail, District, CitizenProfile, User, CeseElectricalInstallationDetails, FileManagement, CitizenServiceDetails,CitizenServiceDetailByCitizenService) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.ceseElectricalInstallationDetails = entity;
        vm.citizenServiceDetails = citizenServiceDetail;
        vm.save = save;
        vm.clear = clear;
        vm.citizen = null;
        var ceseElectricalInstallationDetailsObject = null;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();

        }

//        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

            });
        }

        vm.districts = [];
        loadAllDistrict();
        function loadAllDistrict() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
        }

        function save () {
            vm.isSaving = true;
            vm.ceseElectricalInstallationDetails.installationOwnerDistrictId = $scope.selectedDistrict.id;
            vm.ceseElectricalInstallationDetails.installationOwnerDistrict = $scope.selectedDistrict.districtName;
            vm.ceseElectricalInstallationDetails.applicationNo = $stateParams.applicationNo;
            if (vm.ceseElectricalInstallationDetails.id !== null) {
                CeseElectricalInstallationDetails.update(vm.ceseElectricalInstallationDetails, onSaveSuccess, onSaveError);
            } else {
                CeseElectricalInstallationDetails.save(vm.ceseElectricalInstallationDetails, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
//            $scope.$emit('eServicesApp:ceseElectricalInstallationDetailsUpdate', result);
            saveAttachmentFile(result);

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveAttachmentFile(result)
        {
//            $scope.$emit('eServicesApp:serviceAttachemntUpdate', result);
            ceseElectricalInstallationDetailsObject = result;
            var filename = result.id;
            var file =vm.attachmentFile.file;
            FileManagement.saveFile(file,filename);
            vm.isSaving = false;
            var fileNameArray = vm.attachmentFile.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
               fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
               inputFileName=inputFileName+"."+fileextension;
            }
            ceseElectricalInstallationDetailsObject.attachmentFileTitle=vm.attachmentFile.attachmentTitle;
            ceseElectricalInstallationDetailsObject.attachmentFileExtension = fileextension;
            CeseElectricalInstallationDetails.update(ceseElectricalInstallationDetailsObject, onUpdateSuccess, onUpdateError);

        }
        function onUpdateSuccess (result) {
//            $scope.$emit('eServicesApp:ceseElectricalInstallationDetailsUpdate', result);
            vm.citizenServiceDetails[0].isSiteCheckedByContractor = true;
            CitizenServiceDetails.update(vm.citizenServiceDetails[0], onSaveCitizenServiceDetailsSuccess, onSaveCitizenServiceDetailsError);

        }
        function onUpdateError () {
            vm.isSaving = false;
        }

        function onSaveCitizenServiceDetailsSuccess (result) {
            $scope.$emit('eServicesApp:citizenServiceDetailsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveCitizenServiceDetailsError () {
            vm.isSaving = false;
        }

    }
})();
