(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .controller('AuthenticateChartered', AuthenticateChartered);

    AuthenticateChartered.$inject = ['$stateParams','$scope', 'Principal', 'LoginService', '$state', 'Citizen', 'DepartmentUserProfile', 'AuthenticateChartered','CharteredStatics'];

    function AuthenticateChartered ($stateParams,$scope, Principal, LoginService, $state, Citizen, DepartmentUserProfile, AuthenticateChartered, CharteredStatics) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.charteredStatics= null;
        vm.charteredType = "New";
        vm.login = LoginService.open;
        vm.register = register;
        vm.citizen = {};
        vm.status = $stateParams.status;
       // alert(vm.status);
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
//                $scope.auth = account.authorities;
           });
        }

        getCharteredStatics();
        function getCharteredStatics() {
            CharteredStatics.get(function(result) {
                vm.charteredStatics = result;

                vm.searchQuery = null;
            });
        }

        getNewChartered();

        function getNewChartered() {
           vm.role = "[ROLE_CHARTERED]";
           vm.NewChartered = AuthenticateChartered.query({status: $stateParams.status}, function(result){
            vm.newAuthChartered = result;
           })
         }

        vm.accept =   function accept (authenticateChartered) {
            vm.citizen.id = authenticateChartered.id;
            vm.citizen.citizenFirstName = authenticateChartered.citizenFirstName;
            vm.citizen.citizenMiddleName = authenticateChartered.citizenMiddleName;
            vm.citizen.citizenLastName = authenticateChartered.citizenLastName;
            vm.citizen.city = authenticateChartered.city;
            vm.citizen.districtId = authenticateChartered.districtId;
            vm.citizen.emailId = authenticateChartered.emailId;
            vm.citizen.loginName = authenticateChartered.loginName;
            vm.citizen.mobileNumber = authenticateChartered.mobileNumber;
            vm.citizen.phoneNumber = authenticateChartered.phoneNumber;
            vm.citizen.pinCode = authenticateChartered.pinCode;
            vm.citizen.stateId = authenticateChartered.stateId;
            vm.citizen.address1 = authenticateChartered.address1;
            vm.citizen.address2 = authenticateChartered.address2;
            vm.citizen.authorities = authenticateChartered.authorities;
            vm.citizen.isCharteredAuthenticate = "Deactive";
            vm.isSaving = true;
             console.log(vm.citizen);
             if (vm.citizen.id !== null) {
                 Citizen.update(vm.citizen, onSaveSuccess, onSaveError);
             } else {
                 Citizen.save(vm.citizen, onSaveSuccess, onSaveError);
             }
        }

        vm.reject =   function reject (authenticateChartered) {
             vm.citizen.id = authenticateChartered.id;
             vm.citizen.citizenFirstName = authenticateChartered.citizenFirstName;
             vm.citizen.citizenMiddleName = authenticateChartered.citizenMiddleName;
             vm.citizen.citizenLastName = authenticateChartered.citizenLastName;
             vm.citizen.city = authenticateChartered.city;
             vm.citizen.districtId = authenticateChartered.districtId;
             vm.citizen.emailId = authenticateChartered.emailId;
             vm.citizen.loginName = authenticateChartered.loginName;
             vm.citizen.mobileNumber = authenticateChartered.mobileNumber;
             vm.citizen.phoneNumber = authenticateChartered.phoneNumber;
             vm.citizen.pinCode = authenticateChartered.pinCode;
             vm.citizen.stateId = authenticateChartered.stateId;
             vm.citizen.address1 = authenticateChartered.address1;
             vm.citizen.address2 = authenticateChartered.address2;
             vm.citizen.authorities = authenticateChartered.authorities;
             vm.citizen.isCharteredAuthenticate = "Active";
             vm.isSaving = true;
              console.log(vm.citizen);
              if (vm.citizen.id !== null) {
                  Citizen.update(vm.citizen, onSaveSuccess, onSaveError);
              } else {
                  Citizen.save(vm.citizen, onSaveSuccess, onSaveError);
              }
       }

       function onSaveSuccess(){
          //alert("Success");
          location.reload();
       }
       function onSaveError () {
         alert("Fail!");
         vm.isSaving = false;
       }

       function register () {
        $state.go('register');
       }
    }
})();
