(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('detail-of-approval', {
            parent: 'app',
            url: '/detail-of-approval',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/detail-of-approval/detail-of-approval.html',
                    controller: 'DetailOfApprovalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                            firmNameAndAddress: null,
                            capacityTransformer: null,
                            capacityGeneratingSet: null,
                            newPeriodical: null,
                            dateOfInspection: null,
                            voltageLevel: null,
                            id: null
                        };
                },

            }
        });
    }


})();
