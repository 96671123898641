(function(){
    'use strict';

    angular
        .module('eServicesApp')
        .controller('serviceLogController', serviceLogController);

    serviceLogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'downloadService'];
    function serviceLogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, downloadService) {
        var vm = this;
        vm.citizenSercviceLogDetailsByCitizen = entity;
        console.log(entity);
        vm.clear = clear;
        vm.downloadFile=downloadFile;
//        vm.ServiceLog = serviceLogList;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function downloadFile(filename, downloadFileName)
        {
            downloadService.download(filename, downloadFileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

    }
})();




