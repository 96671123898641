(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('DepartmentSrvController', DepartmentSrvController);

    DepartmentSrvController.$inject = ['DepartmentSrv'];

    function DepartmentSrvController(DepartmentSrv) {

        var vm = this;

        vm.departmentSrvs = [];

        loadAll();

        function loadAll() {
            DepartmentSrv.query(function(result) {
                vm.departmentSrvs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
