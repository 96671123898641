(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('applied-services', {
            parent: 'app',
            url: '/applied-services',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Applied Services'
            },
            views: {
                'content@': {
                    templateUrl: 'app/action-taken-on-applied-service/applied-services.html',
                    controller: 'AppliedServicesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })

      .state('applied-services-form', {
            parent: 'applied-services',
            url: '/applied-services-form/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ServiceFormField'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                  $uibModal.open({
                      templateUrl: 'app/action-taken-on-applied-service/service-form-dialog.html',
                      controller: 'ServiceFormDialogController',
                      controllerAs: 'vm',
                      backdrop: 'static',
                      size: 'lg',
                      resolve: {
//                          entity: ['ServiceFormField', function(ServiceFormField) {
//                              return ServiceFormField.get({id : $stateParams.id}).$promise;
//                          }]
                      }
                  }).result.then(function() {
                      $state.go('^', {}, { reload: false });
                  }, function() {
                      $state.go('^');
                  });
              }]
        })
//        .state('service-form-field-detail.edit', {
//            parent: 'service-form-field-detail',
//            url: '/detail/edit',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/service-form-field/service-form-field-dialog.html',
//                    controller: 'ServiceFormFieldDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['ServiceFormField', function(ServiceFormField) {
//                            return ServiceFormField.get({id : $stateParams.id}).$promise;
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('^', {}, { reload: false });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })
//        .state('service-form-field.new', {
//            parent: 'service-form-field',
//            url: '/new',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/service-form-field/service-form-field-dialog.html',
//                    controller: 'ServiceFormFieldDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: function () {
//                            return {
//                                fieldName: null,
//                                fieldType: null,
//                                fieldTitle: null,
//                                isrequired: null,
//                                options: null,
//                                palceHolder: null,
//                                fieldOrder: null,
//                                departmentId: null,
//                                id: null
//                            };
//                        }
//                    }
//                }).result.then(function() {
//                    $state.go('service-form-field', null, { reload: 'service-form-field' });
//                }, function() {
//                    $state.go('service-form-field');
//                });
//            }]
//        })
//        .state('service-form-field.edit', {
//            parent: 'service-form-field',
//            url: '/{id}/edit',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/service-form-field/service-form-field-dialog.html',
//                    controller: 'ServiceFormFieldDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['ServiceFormField', function(ServiceFormField) {
//                            return ServiceFormField.get({id : $stateParams.id}).$promise;
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('service-form-field', null, { reload: 'service-form-field' });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })
//        .state('service-form-field.delete', {
//            parent: 'service-form-field',
//            url: '/{id}/delete',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/service-form-field/service-form-field-delete-dialog.html',
//                    controller: 'ServiceFormFieldDeleteController',
//                    controllerAs: 'vm',
//                    size: 'md',
//                    resolve: {
//                        entity: ['ServiceFormField', function(ServiceFormField) {
//                            return ServiceFormField.get({id : $stateParams.id}).$promise;
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('service-form-field', null, { reload: 'service-form-field' });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        });
    }

})();
