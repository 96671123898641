(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .factory('DepartmentSrvByDepartment', DepartmentSrvByDepartment);

    DepartmentSrvByDepartment.$inject = ['$resource'];

    function DepartmentSrvByDepartment ($resource) {
        var resourceUrl =  'api/services-by-department/:departmentId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
