(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CeseElectricalInstallationDetailsDialogController', CeseElectricalInstallationDetailsDialogController);

    CeseElectricalInstallationDetailsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CeseElectricalInstallationDetails'];

    function CeseElectricalInstallationDetailsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CeseElectricalInstallationDetails) {
        var vm = this;

        vm.ceseElectricalInstallationDetails = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.ceseElectricalInstallationDetails.id !== null) {
                CeseElectricalInstallationDetails.update(vm.ceseElectricalInstallationDetails, onSaveSuccess, onSaveError);
            } else {
                CeseElectricalInstallationDetails.save(vm.ceseElectricalInstallationDetails, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('eServicesApp:ceseElectricalInstallationDetailsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.checkedDate = false;
        vm.datePickerOpenStatus.actionTakenOnDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
