(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('SelfCertifiedController', SelfCertifiedController);

    SelfCertifiedController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'DepartmentSrvDetail','CitizenServiceDetails', 'CitizenServiceLog', 'SelfCertified'];

    function SelfCertifiedController ($scope, Principal, LoginService, $state, DepartmentSrvDetail, CitizenServiceDetails, CitizenServiceLog, SelfCertified) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.departmentSrvDetail = [];
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadAll();
            });
        }

        function register () {
            $state.go('register');
        }

        function loadAll() {
            SelfCertified.query(function(result) {
                vm.citizenServiceCompleteDetails = result;
               // vm.citizenServiceCompleteDetails.length;
                vm.searchQuery = null;
            });
        }






      }
})();
