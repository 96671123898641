(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('citizen-service-details', {
            parent: 'entity',
            url: '/citizen-service-details',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceDetails'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details.html',
                    controller: 'CitizenServiceDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('citizen-service-details-detail', {
            parent: 'citizen-service-details',
            url: '/citizen-service-details/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'CitizenServiceDetails'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details-detail.html',
                    controller: 'CitizenServiceDetailsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'CitizenServiceDetails', function($stateParams, CitizenServiceDetails) {
                    return CitizenServiceDetails.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'citizen-service-details',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('citizen-service-details-detail.edit', {
            parent: 'citizen-service-details-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details-dialog.html',
                    controller: 'CitizenServiceDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceDetails', function(CitizenServiceDetails) {
                            return CitizenServiceDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-details.new', {
            parent: 'citizen-service-details',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details-dialog.html',
                    controller: 'CitizenServiceDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                isApplied: null,
                                appliedOnDate: null,
                                appliedBy: null,
                                isAssigned: null,
                                assignedBy: null,
                                assignedOnDate: null,
                                isFormFilled: null,
                                formFilledBy: null,
                                formFilledOnDate: null,
                                isRequired: null,
                                isPaymentMade: null,
                                paymentMadeOnDate: null,
                                paymentMadeBy: null,
                                isRejected: null,
                                rejectedBy: null,
                                rejectedOnDate: null,
                                isClearanceGiven: null,
                                clearanceGivenBy: null,
                                clearanceGivenOnDate: null,
                                isDeactivated: null,
                                lastComment: null,
                                pinCode: null,
                                requiredBy: null,
                                requiredOnDate: null,
                                deactivatedBy: null,
                                deactivatedOnDate: null,
                                isNotRequired: null,
                                notRequiredBy: null,
                                notRequiredOnDate: null,
                                departmentId: null,
                                status: null,
                                lastCommentedDate: null,
                                zoneName_CentreName: null,
                                isCharteredAssign: null,
                                charteredAssignBy: null,
                                chartered_id: null,
                                inspectionThrough: null,
                                charteredAssignOnDate: null,
                                isSiteCheckedByContractor: null,
                                pendencyStatus: null,
                                userType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('citizen-service-details', null, { reload: 'citizen-service-details' });
                }, function() {
                    $state.go('citizen-service-details');
                });
            }]
        })
        .state('citizen-service-details.edit', {
            parent: 'citizen-service-details',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details-dialog.html',
                    controller: 'CitizenServiceDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CitizenServiceDetails', function(CitizenServiceDetails) {
                            return CitizenServiceDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-details', null, { reload: 'citizen-service-details' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('citizen-service-details.delete', {
            parent: 'citizen-service-details',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/citizen-service-details/citizen-service-details-delete-dialog.html',
                    controller: 'CitizenServiceDetailsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CitizenServiceDetails', function(CitizenServiceDetails) {
                            return CitizenServiceDetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('citizen-service-details', null, { reload: 'citizen-service-details' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
