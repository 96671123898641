(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('viewInspectionReportController', viewInspectionReportController);

    viewInspectionReportController.$inject = ['$timeout', '$state', 'Auth', '$scope', '$stateParams', '$uibModalInstance', 'Principal', 'entity', 'District', 'CitizenProfile', 'User', 'CeseElectricalInstallationDetails', 'FileManagement','CeseElectricalInstallationDetailsByApplicationNo', 'downloadService'];

    function viewInspectionReportController ($timeout, $state, Auth,  $scope, $stateParams, $uibModalInstance, Principal, entity, District, CitizenProfile, User, CeseElectricalInstallationDetails, FileManagement, CeseElectricalInstallationDetailsByApplicationNo, downloadService) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.ceseElectricalInstallationDetails = entity;
        vm.clear = clear;
        vm.citizen = null;
        var ceseElectricalInstallationDetailsObject = null;
        vm.downloadFile=downloadFile;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();

        }

//        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

            });
        }

        vm.districts = District.get({id : vm.ceseElectricalInstallationDetails.installationOwnerDistrictId});

        function downloadFile(filename, fileExtension, downloadFileName)
        {
        var fileNameWithExtension = filename +"." + fileExtension;
            downloadService.download(fileNameWithExtension, downloadFileName)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }
    }
})();
