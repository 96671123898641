(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .controller('CitizenNewController', CitizenNewController);

    CitizenNewController.$inject = ['$scope', 'Principal', 'LoginService', '$state','entity', 'citizenServiceLog', 'DepartmentSrvDetail','CitizenServiceDetails', 'CitizenServiceLog'];

    function CitizenNewController ($scope, Principal, LoginService, $state, entity, citizenServiceLog, DepartmentSrvDetail, CitizenServiceDetails, CitizenServiceLog) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.departmentSrvDetail = [];
        vm.applyForService = applyForService;
        vm.citizenServiceDetails = entity;
        vm.citizenServiceLog = citizenServiceLog;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadAll();
            });
        }

        function register () {
            $state.go('register');
        }

        function loadAll() {
            DepartmentSrvDetail.query(function(result) {
                vm.departmentSrvDetail = result;
                vm.searchQuery = null;
            });
        }

        function applyForService(departmentSrv)
        {
            vm.citizenServiceDetails.citizenId = vm.account.id;
            vm.citizenServiceDetails.serviceId = departmentSrv.id;
            vm.citizenServiceDetails.departmentId = departmentSrv.departmentId;
            vm.citizenServiceDetails.isRequired = true;
            CitizenServiceDetails.update(vm.citizenServiceDetails, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            alert("success");
            $scope.$emit('eServicesApp:citizenServiceDetailsUpdate', result);
//            $uibModalInstance.close(result);
            vm.citizenServiceLog.actionTaken = "Applied";
            vm.citizenServiceLog.comment = "Applied for Service";
            vm.citizenServiceLog.citizenId = result.citizenId;
            vm.citizenServiceLog.serviceId = result.serviceId;
            vm.citizenServiceLog.applicationNo = result.pinCode;
            CitizenServiceLog.save(vm.citizenServiceLog, onSaveServiceLogSuccess, onSaveServiceLogError);
            $state.go('citizen-dashboard');
        }

        function onSaveError () {
            alert("Fail!");
            vm.isSaving = false;
        }

         function onSaveServiceLogSuccess () {
            vm.isSaving = false;
        }
        function onSaveServiceLogError () {
            vm.isSaving = false;
        }


    }
})();
