(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('citizen-dashboard', {
            parent: 'app',
            url: '/citizen-dashboard',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/citizen-dashboard/citizen-dashboard.html',
                    controller: 'CitizenDashboardController',
                    controllerAs: 'vm'
                }

            }
        })

        .state('citizen-comment', {
            parent: 'citizen-dashboard',
            url: '/citizen-comment?:citizenId:serviceId:applicationNo',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_LICENSE_USER','ROLE_HOD','ROLE_HEPC_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/citizen-dashboard/comment-dialog.html',
                    controller: 'citizenCommentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    keyboard: false,
                    resolve: {
                        citizenServiceLog: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                actionTaken: null,
                                actionTakenBy: null,
                                actionTakenDate: null,
                                comment: null,
                                id: null
                            };

                        },
                        citizenServiceAttachment: function () {
                            return {
                                serviceLogId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                attachmentExtension: null,
                                id: null
                            };
                        },
                        citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                            return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                        }]

                    }
               }).result.then(function() {
                   $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
               }, function() {
                   $state.go('^');
               });
            }]
        })

       .state('fill-service-form', {
           parent: 'citizen-dashboard',
           url: '/fillform?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_LICENSE_USER','ROLE_RM','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
           },
            views: {
                'content@': {
                   templateUrl: 'app/citizen-dashboard/fill-service-form-dialog.html',
                   controller: 'fillServiceFormController',
                   controllerAs: 'vm'
                }
            },
            resolve: {
                citizenServiceLog: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        comment: null,
                        applicationNo: null,
                        id: null
                    };
                },

                citizenServiceFormFieldData: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        formFieldId: null,
                        formFieldData: null,
                        applicationNo: null,
                        id: null
                    };
                },
                citizenServiceAttachment: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        attachmentTitle: null,
                        attachmentFile: null,
                        fileExtension: null,
                        applicationNo: null,
                        id: null
                    };
                },
                serviceFormAttachment: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        attachmentTitle: null,
                        attachmentFile: null,
                        fileExtension: null,
                        isDraft: null,
                        applicationNo: null,
                        id: null
                    };
                }

            }
        })

        .state('upload-attachment', {
            parent: 'fill-service-form',
            url: '/uploadAttachment',
            data: {
                authorities: ['ROLE_USER', 'ROLE_LICENSE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/citizen-dashboard/upload-service-form-attachment-dialog.html',
                    controller: 'uploadServiceAttachment',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                citizenId: null,
                                serviceId: null,
                                attachmentTitle: null,
                                attachmentFile: null,
                                fileExtension: null,
                                isDraft: null,
                                id: null
                            };
                        }

                    }
                }).result.then(function() {
                    $state.go('fill-service-form', null, { reload: 'fill-service-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('service-log', {
            parent: 'citizen-dashboard',
            url: '/service-log?:citizenId:serviceId',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_LICENSE_USER','ROLE_HOD','ROLE_HEPC_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/citizen-dashboard/service-log-dialog.html',
                    controller: 'serviceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                   entity: ['CitizenServiceLogDetailsByCitizen', function(CitizenServiceLogDetailsByCitizen) {
                        return CitizenServiceLogDetailsByCitizen.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId});
                    }]
                    }
                }).result.then(function() {
                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    .state('assign-chartered', {
            parent: 'citizen-dashboard',
            url: '/assign-chartered?:citizenId:serviceId:applicationNo',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/citizen-dashboard/assign-chartered-dialog.html',
                    controller: 'AssignCharteredController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    citizenServiceLog: function () {
                        return {
                            citizenId: null,
                            serviceId: null,
                            actionTaken: null,
                            actionTakenBy: null,
                            actionTakenDate: null,
                            comment: null,
                            id: null
                        };

                    },
                    citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                     return CitizenServiceDetailByCitizenService.query({citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                    }]
                    }
                }).result.then(function() {
                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                }, function() {
//                    $state.go('^');
                    $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                });
            }]
        })


        .state('view-assigned-chartered', {
                    parent: 'citizen-dashboard',
                    url: '/view-assigned-chartered?:charteredId:citizenId:serviceId:applicationNo',
                    data: {
                        authorities: []
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal','$window', function($stateParams, $state, $uibModal,$window) {
                        $uibModal.open({
                            templateUrl: 'app/citizen-dashboard/view-assigned-chartered-dialog.html',
                            controller: 'ViewAssignCharteredController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            keyboard:false,
                            resolve: {
                                citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                                 return CitizenServiceDetailByCitizenService.query({charteredId:$stateParams.charteredId,citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                                }]
                            }
                        }).result.then(function() {
                            $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                        }, function() {
                            $state.go('^');
                        });
                    }]
                })

        .state('application-log', {
            parent: 'citizen-dashboard',
            url: '/application-log?:applicationNo',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_LICENSE_USER','ROLE_HOD','ROLE_HEPC_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/citizen-dashboard/service-log-dialog.html',
                    controller: 'serviceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard:false,
                    size: 'lg',
                    resolve: {
                   entity: ['CitizenServiceLogDetailsByApplicationNo', function(CitizenServiceLogDetailsByApplicationNo) {
                        return CitizenServiceLogDetailsByApplicationNo.query({applicationNo:$stateParams.applicationNo});
                    }]
                    }
                }).result.then(function() {
                    $state.go('^', null);
                }, function() {
                    $state.go('^');
                });
            }]
        })

        $stateProvider.state('citizen-service-fee-detail', {
            parent: 'app',
            url: '/citizen-service-fee-detail?:citizenId:serviceId:applicationNo',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/citizen-dashboard/citizen-service-fee-detail.html',
                    controller: 'citizenServiceFeeDetailController',
                    controllerAs: 'vm'
                }
            }
        })



       .state('service-payment', {
        parent: 'citizen-dashboard',
        url: '/service-payment?:citizenId:serviceId:feeAmount:id',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_RM','ROLE_HEPC_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/citizen-dashboard/service-payment-dialog.html',
                controller: 'servicePaymentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
//                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
//                        $translatePartialLoader.addPart('home');
//                        return $translate.refresh();
//                    }],
//                    serviceLogList: ['GetServiceLog', function(GetServiceLog) {
//                        return GetServiceLog.query({projectid:$stateParams.projectid,serviceid:$stateParams.serviceid});
//                    }]
                }
            }).result.then(function() {
                $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
            }, function() {
                $state.go('^');
            });
        }]
    })

    .state('filledServiceForm-view', {
        parent: 'citizen-dashboard',
        url: '/filledserviceform?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_LICENSE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
           },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
               $uibModal.open({
                   templateUrl: 'app/service-clearance-action/serviceFormFilled.html',
                   controller: 'serviceFormFilledController',
                   controllerAs: 'vm',
                   backdrop: 'static',
                   size: 'lg',
                   resolve: {
                        departmentSrv: ['DepartmentSrv', function(DepartmentSrv) {
                            return DepartmentSrv.get({id : $stateParams.serviceId}).$promise;
                        }]

                    }
               }).result.then(function() {
                     $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                 }, function() {
                     $state.go('^');
                 });
            }]

    })

    .state('inspection-details', {
        parent: 'citizen-dashboard',
        url: '/inspection-details?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
           },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
               $uibModal.open({
                   templateUrl: 'app/citizen-dashboard/inspection-report.html',
                   controller: 'inspectionReportController',
                   controllerAs: 'vm',
                   backdrop: 'static',
                   size: 'lg',
                   resolve: {
                        entity: function () {
                            return {
                                isSiteChecked: null,
                                checkedDate: null,
                                installationOwnerName: null,
                                installtionOwnerState: null,
                                installationOwnerStateId: null,
                                installationOwnerDistrict: null,
                                installationOwnerDistrictId: null,
                                installationOwnerTehsile: null,
                                installationOwnerAddress: null,
                                installationOwnerPin: null,
                                anyObservationNoticed: null,
                                observationConveytoEI: null,
                                attachedReportForm: null,
                                actionTakenBy: null,
                                actionTakenOnDate: null,
                                applicationNo: null,
                                checkedDateHour: null,
                                checkedDateMinute: null,
                                responsiblePersonName: null,
                                responsiblePersonContact: null,
                                id: null
                            };
                        },
                        citizenServiceDetail: ['CitizenServiceDetailByCitizenService', function(CitizenServiceDetailByCitizenService) {
                         return CitizenServiceDetailByCitizenService.query({charteredId:$stateParams.charteredId,citizenId:$stateParams.citizenId,serviceId:$stateParams.serviceId,pinCode:$stateParams.applicationNo}).$promise;
                        }]

                    }
               }).result.then(function() {
                     $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                 }, function() {
                     $state.go('^');
                 });
            }]

    })

    .state('view-inspection-details', {
        parent: 'citizen-dashboard',
        url: '/view-inspection-details?:citizenId:serviceId:applicationNo',
           data: {
               authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_HOD','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICE','ROLE_ACCOUNT_OFFICER', 'ROLE_CHARTERED']
           },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
               $uibModal.open({
                   templateUrl: 'app/citizen-dashboard/view-inspection-report.html',
                   controller: 'viewInspectionReportController',
                   controllerAs: 'vm',
                   backdrop: 'static',
                   size: 'lg',
                   resolve: {
                        entity: ['CeseElectricalInstallationDetailsByApplicationNo', function(CeseElectricalInstallationDetailsByApplicationNo) {
                            return CeseElectricalInstallationDetailsByApplicationNo.get({applicationNo : $stateParams.applicationNo}).$promise;
                        }]

                    }
               }).result.then(function() {
                     $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
                 }, function() {
                     $state.go('^');
                 });
            }]

    })
    .state('self-certification-through-own-engineer', {
    parent: 'citizen-dashboard',
    url: '/selfCertificationthroughownengineer?applicationNo:serviceId:citizenId',
    data: {
        authorities: ['ROLE_USER']
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/citizen-dashboard/inspection-report-by-own-engineer.html',
            controller: 'inspectionReportByOwnEngineerController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                citizenServiceLog: function () {
                    return {
                        citizenId: null,
                        serviceId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        comment: null,
                        id: null
                    };

                },
                entity: function () {
                    return {
                        nameOfCertifyingEngineer: null,
                        mobileNoOfCertifyingEngineer: null,
                        qualificationOfCertifyingEngineer: null,
                        nameOfFirmCompanyOwner: null,
                        addressOfFirmCompanyOwner: null,
                        districtOfFirmCompanyOwner: null,
                        contactPersonNameOfFirmCompanyOwner: null,
                        mobileNoContactPersonOfFirmCompanyOwner: null,
                        voltageLevel: null,
                        sanctionedLoad: null,
                        ratingCapacityOfDGSETTurbine: null,
                        ratingCapacityOfSolarPowerPlant: null,
                        detailOfsanctionedSinglelineDiagamMemoNo: null,
                        detailOfsanctionedSinglelineDiagamDated: null,
                        certification: false,
                        dateOfSelfCertification: null,
                        uploadedFormI: null,
                        uploadedFormII: null,
                        uploadedFormIII: null,
                        applicationNo: null,
                        applicationUploadedDate: null,
                        uploadedBy: null,
                        serviceId: null,
                        serviceStatus: null,
                        ratingCapacityOfTransformer: null,
                        attachmentForm1title: null,
                        attachmentForm2Title: null,
                        serviceName: null,
                        id: null,
                        ownEngineerSince: null
                    };
                }
            }
            }).result.then(function() {
                $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
            }, function() {
                $state.go('^');
            });
        }]
    })

    .state('view-self-certification-through-own-engineer', {
        parent: 'citizen-dashboard',
        url: '/view-selfCertificationthroughownengineer?applicationNo:serviceId:citizenId',
        data: {
            authorities: ['ROLE_USER','ROLE_ADMIN', 'ROLE_HOD']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/citizen-dashboard/view-inspection-report-by-own-engineer.html',
                controller:  'viewInspectionReportByOwnEngineerController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['SelfCertificationThroughOwnEngineersByApplicationNo', function(SelfCertificationThroughwnEngineersByApplicationNo) {
                        return SelfCertificationThroughwnEngineersByApplicationNo.get({applicationNo : $stateParams.applicationNo}).$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('citizen-dashboard', null, { reload: 'citizen-dashboard' });
            }, function() {
                $state.go('^');
            });
        }]
    });


    }

})();
