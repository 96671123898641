(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .directive('fileModel', ['$parse', function ($parse) {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    var model = $parse(attrs.fileModel);
                    var modelSetter = model.assign;
                    var maxSize = 1024*1024; //1 MB
                     var validFormats = ['pdf', 'PDF'];

                    element.bind('change', function(){
                        scope.$apply(function(){
                            scope.maxSizeError = false;
                            scope.notValidFileFormat = false;
                            if (element[0].files.length > 1) {
                                modelSetter(scope, element[0].files);
                                } else {
                                modelSetter(scope, element[0].files[0]);
                                }
                            var fileSize = element[0].files[0].size;
                            if (fileSize > maxSize) {
                                scope.maxSizeError = true;

                            }
                             var value = element.val(),
                                ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
                                if(ext !== "pdf")
                                {
                                    scope.notValidFileFormat = true;
                                }
                        });
                    });
                }
            };
        }]);
})();
