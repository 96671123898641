(function() {
    'use strict';
    angular
        .module('eServicesApp')
        .controller('serviceClearanceActionController', serviceClearanceActionController);

    serviceClearanceActionController.$inject = ['$filter','$timeout', '$scope', '$stateParams','$state', 'LoginService', 'Auth', 'Principal', 'allServicesInProcess','AllClearedServicesInProcess','DepartmentUserProfile'];

    function serviceClearanceActionController ($filter, $timeout, $scope, $stateParams, $state, LoginService, Auth, Principal, allServicesInProcess, AllClearedServicesInProcess,  DepartmentUserProfile) {

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        var vm = this;
        vm.account = null;
        vm.selectedValue = "10";
        vm.isAuthenticated = null;
        vm.allServicesInProcessAllCleared = [];
//        vm.serviceStatistics = serviceStatistics;
        vm.allServicesInProcess = allServicesInProcess;
        vm.login = LoginService.open;
        vm.register = register;
        vm.title = $stateParams.status;
        vm.fromDate = "";
        vm.toDate = "";
        vm.fromDateVal ="";
        vm.toDateVal ="";
        vm.searchData = searchData;
        getAccount();
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function searchData(){
            var dateFormat = 'yyyy-MM-dd';
            vm.fromDateVal = $filter('date')(vm.fromDate, dateFormat);
            // alert(vm.fromDateVal);
            vm.toDateVal = $filter('date')(vm.toDate, dateFormat);
            // alert(vm.toDateVal);
            vm.allServicesInProcess = AllClearedServicesInProcess.query({status:"Cleared", fromDate:vm.fromDateVal, toDate:vm.toDateVal});
            console.log("Service List",vm.allServicesInProcess);
        }
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
//        setDefaultPageVal();
//
//        function setDefaultPageVal() {
//            vm.selectedValue = 10;
//        }
        function register () {
            $state.go('register');
        }

        $scope.setPageSize = function (pageSizeVal) {

            $scope.pageSize = pageSizeVal;

        }

    }
})();
