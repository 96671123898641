(function() {
    'use strict';

    angular
        .module('eServicesApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('self-certification-by-own-engineer', {
            parent: 'entity',
            url: '/self-certification-by-own-engineer',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SelfCertificationByOwnEngineers'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineers.html',
                    controller: 'SelfCertificationByOwnEngineerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('self-certification-by-own-engineer-detail', {
            parent: 'self-certification-by-own-engineer',
            url: '/self-certification-by-own-engineer/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'SelfCertificationByOwnEngineer'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineer-detail.html',
                    controller: 'SelfCertificationByOwnEngineerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'SelfCertificationByOwnEngineer', function($stateParams, SelfCertificationByOwnEngineer) {
                    return SelfCertificationByOwnEngineer.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'self-certification-by-own-engineer',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('self-certification-by-own-engineer-detail.edit', {
            parent: 'self-certification-by-own-engineer-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineer-dialog.html',
                    controller: 'SelfCertificationByOwnEngineerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SelfCertificationByOwnEngineer', function(SelfCertificationByOwnEngineer) {
                            return SelfCertificationByOwnEngineer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('self-certification-by-own-engineer.new', {
            parent: 'self-certification-by-own-engineer',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineer-dialog.html',
                    controller: 'SelfCertificationByOwnEngineerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nameOfCertifyingEngineer: null,
                                mobileNoOfCertifyingEngineer: null,
                                qualificationOfCertifyingEngineer: null,
                                nameOfFirmCompanyOwner: null,
                                addressOfFirmCompanyOwner: null,
                                districtOfFirmCompanyOwner: null,
                                contactPersonNameOfFirmCompanyOwner: null,
                                mobileNoContactPersonOfFirmCompanyOwner: null,
                                voltageLevel: null,
                                sanctionedLoad: null,
                                ratingCapacityOfDGSETTurbine: null,
                                ratingCapacityOfSolarPowerPlant: null,
                                detailOfsanctionedSinglelineDiagamMemoNo: null,
                                detailOfsanctionedSinglelineDiagamDated: null,
                                certification: null,
                                dateOfSelfCertification: null,
                                uploadedFormI: null,
                                uploadedFormII: null,
                                uploadedFormIII: null,
                                applicationNo: null,
                                applicationUploadedDate: null,
                                uploadedBy: null,
                                serviceId: null,
                                serviceStatus: null,
                                ratingCapacityOfTransformer: null,
                                attachmentForm1title: null,
                                attachmentForm2Title: null,
                                serviceName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('self-certification-by-own-engineer', null, { reload: 'self-certification-by-own-engineer' });
                }, function() {
                    $state.go('self-certification-by-own-engineer');
                });
            }]
        })
        .state('self-certification-by-own-engineer.edit', {
            parent: 'self-certification-by-own-engineer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineer-dialog.html',
                    controller: 'SelfCertificationByOwnEngineerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SelfCertificationByOwnEngineer', function(SelfCertificationByOwnEngineer) {
                            return SelfCertificationByOwnEngineer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('self-certification-by-own-engineer', null, { reload: 'self-certification-by-own-engineer' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('self-certification-by-own-engineer.delete', {
            parent: 'self-certification-by-own-engineer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/self-certification-by-own-engineer/self-certification-by-own-engineer-delete-dialog.html',
                    controller: 'SelfCertificationByOwnEngineerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SelfCertificationByOwnEngineer', function(SelfCertificationByOwnEngineer) {
                            return SelfCertificationByOwnEngineer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('self-certification-by-own-engineer', null, { reload: 'self-certification-by-own-engineer' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
